import React, { useState } from 'react';
import clsx from "clsx";
import {
    Autocomplete,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import agent from "../../agent/agent";
import { makeStyles } from "@mui/styles";

let timeout = null;

const AutocompleteUser = (
    {
        name,
        value,
        touched,
        error,

        title = "Выберите пользователя",
        limit = 0,
        roles = "",
        addedUsers = [],

        showSearchFields = true,

        onChangeAutocomplete
    }
) => {

    const classes = useStyles();
    const [ users, setUsers ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ isOpenAutocomplete, setIsOpenAutocomplete ] = useState( false );
    const [ searchField, setSearchField ] = useState( 'name' );

    const getUsers = async (value) => {
        const response = await agent.get( `/users?${ searchField }=${ value }&limit=${ limit || 0 }&roles=${ roles }&active=1` )
            .then( res => res.data.users )
            .catch( err => [] );
        setUsers( response );
    };

    const resetUsers = () => {
        setUsers( [] );
    };

    const userAddedBoolean = (id) => {
        return addedUsers.find( user => user.id === id );
    };

    const handleChangeSelect = ({ target }) => {
        const { value } = target;
        setSearchField( value );
    };

    const handleChange = (value) => {
        onChangeAutocomplete( value );
        resetUsers();
    };

    const onInputAutocomplete = (e, value) => {
        clearTimeout( timeout );
        timeout = null;
        if (value.length >= 2) {
            timeout = setTimeout( async () => {
                setUsers( [] );
                setIsLoading( true );
                await getUsers( value );
                setIsLoading( false );
            }, 1000 );
        } else {
            resetUsers();
        }
    };

    return (
        <>
            <Typography
                pl={ 1 }
                mb={ 0.5 }
                className={ clsx( {
                    [classes.errorTitle]: Boolean( touched && error ),
                } ) }
                variant="h6"
            >
                { title }
            </Typography>
            <Box
                className={ clsx( {
                    [classes.root]: true,
                    [classes.error]: Boolean( touched && error ),
                } ) }
            >
                { showSearchFields && (
                    <FormControl
                        error={ Boolean( touched && error ) }
                        fullWidth
                        sx={ { marginBottom: 2 } }
                    >
                        <InputLabel id="add-employee-select-label">Поиск по полю:</InputLabel>
                        <Select
                            error={ Boolean( touched && error ) }
                            labelId="add-employee-select-label"
                            id="add-employee-select"
                            value={ searchField }
                            label="Поиск по полю:"

                            onChange={ handleChangeSelect }
                        >
                            <MenuItem value="name">Имя</MenuItem>
                            <MenuItem value="phone">Телефон</MenuItem>
                        </Select>
                    </FormControl>
                ) }
                <Box>
                    <Autocomplete
                        fullWidth
                        disableClearable
                        open={ isOpenAutocomplete }
                        noOptionsText='Нет пользователей'
                        loadingText='Загрузка...'
                        value={ value }
                        name={ name }
                        options={ users }
                        filterOptions={ (x) => x }
                        getOptionLabel={ (option) => option.firstName || option }
                        renderOption={ (props, option) => (
                            <li
                                { ...props }
                                className={ clsx( {
                                    [classes.listItem]: true,
                                    [classes.addedUser]: userAddedBoolean( option.id ),
                                } ) }
                            >№{ option.id } | { option.lastName } { option.firstName } { option.phone }</li>
                        ) }
                        placeholder={ searchField === 'name' ? "Введите имя пользователя" : "Введите телефон пользователя" }
                        loading={ isLoading }
                        renderInput={ (params) => (
                            <TextField
                                { ...params }
                                label={ searchField === 'name' ? "Имя пользователя" : "Телефон пользователя" }
                                placeholder={ searchField === 'name' ? "Введите имя пользователя" : "Введите телефон пользователя" }
                                error={ Boolean( touched && error ) }
                                helperText={ touched && error }
                                InputProps={ {
                                    ...params.InputProps,
                                    startAdornment: <SearchIcon
                                        color={ Boolean( touched && error ) ? "error" : "primary" }
                                        sx={ { fontSize: 20 } }/>,
                                    endAdornment: (
                                        <React.Fragment>
                                            { isLoading ? <CircularProgress color="primary"
                                                                            size={ 20 }/> : params.InputProps.endAdornment }
                                        </React.Fragment>
                                    ),
                                } }
                            />
                        ) }

                        onInputChange={ onInputAutocomplete }
                        onChange={ (e, value) => handleChange( value ) }
                        onClose={ () => {
                            resetUsers();
                            setIsOpenAutocomplete( false )
                        } }
                        onOpen={ () => setIsOpenAutocomplete( true ) }
                    />
                </Box>
            </Box>
        </>
    );
};

const useStyles = makeStyles( {
    root: {
        padding: "15px 5px 5px",
        borderRadius: 4,
        border: "1px solid gray",
    },
    error: {
        borderColor: "#e53935",
    },
    errorTitle: {
        color: "#e53935",
    },
    listItem: {
        padding: "6px 16px",
        fontSize: 14,
        lineHeight: "21px",
        letterSpacing: "0.05px",
        cursor: 'pointer',
        "&:hover": {
            background: "rgba(0, 0, 0, .1)",
        },
    },
    addedUser: {
        textDecoration: "line-through",
        color: "red",
        pointerEvents: "none",
    }
} );

export default React.memo( AutocompleteUser );
