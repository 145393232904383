import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { convertorNumber } from "../../../../../../helper/convertor";
import { makeStyles } from "@mui/styles";

const MainInformation = (props) => {
    const {
        wallet,
        holdSum,
        isRegularRequest,
        onOpenWithdrawalFunds,
        onCanceledRegularFunds
    } = props;

    const classes = useStyles();

    const handleChangeBoolean = ({ target }) => {
        const { checked } = target;

        if (checked) {
            onOpenWithdrawalFunds( true, 'regular' );

            return null;
        }

        onCanceledRegularFunds();
    };

    return (
        <>
            <Typography variant="h2">Баланс</Typography>
            <Box className={ classes.rowContent } justifyContent="space-between" mt={ 1 } alignItems="center">
                <Box className={ classes.columnContent }>
                    <Box className={ classes.rowContent } mb={ 1 } alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">свободно:</Typography>
                        <Typography variant="h4" textAlign="right">
                            { convertorNumber( wallet?.balance || 0, 2, '.' ) } ₽
                        </Typography>
                    </Box>
                    <Box className={ classes.rowContent } alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">забронировано:</Typography>
                        <Typography variant="h4" textAlign="right">
                            { convertorNumber( +( wallet?.balanceFrozen || 0 ) + +holdSum, 2, '.' ) } ₽
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <Button
                            sx={ {
                                borderRadius: "6px",
                            } }
                            variant="contained"
                            disabled={ isRegularRequest }

                            onClick={ onOpenWithdrawalFunds.bind( this, true, 'oneTime' ) }
                        >
                            Получить оплату
                        </Button>
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="получать автоматически"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={ isRegularRequest }

                                    onChange={ handleChangeBoolean }
                                />
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};


const useStyles = makeStyles( {
    rowContent: {
        display: "flex",
        flexWrap: "wrap",
        gap: "10px 20px",
    },

    columnContent: {
        display: "flex",
        flexDirection: "column",
    },
} );

export default MainInformation;
