import React, { Component } from 'react';
import queryString from "query-string";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import { withStyles } from "@mui/styles";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { MainOrderInformation, OrderActions } from "./components";
import { DialogConfirmation } from "../../../../components";
import {
    LifeCycle as LifeCycleComponent,
    OrderPhotos as OrderPhotosComponent,
    ProductsOrderInformation as ProductsOrderInformationComponent
} from "../../orders/Order/components";

class DelegatedOrder extends Component {
    constructor(props) {
        super( props );

        this.state = {
            order: {},
            hash: null,
            orderId: null,
            delegatedId: null,

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],

            isLoading: true,

            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initParams();

        if (this.state.hash === null) {
            this.props.history.push( `/` );

            Notification( {
                message: "Неверная ссылка, сгенерируйте снова",
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getOrder();
    }

    initParams = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse( locationSearch, {
            arrayFormat: "bracket"
        } );

        await this.setState( {
            hash: parseSearch?.hash || null,
            orderId: parseSearch?.orderId || null,
            delegatedId: parseSearch?.delegatedId || null,
        } );
    };

    getOrder = async () => {
        if (this.state.hash === null || this.state.orderId === null || this.state.delegatedId === null) {
            Notification( {
                message: "Неверная ссылка, сгенерируйте снова",
                type: NotificationTypes.error,
            } );

            this.props.history.push( '/' );

            return;
        }

        this.setState( { isLoading: true } );

        const response = await agent.get( `/delegated-orders/${ this.state.delegatedId }/get-order?hash=${ this.state.hash }` )
            .then( (res) => res.data.order )
            .catch( (err) => {
                return {
                    error: err.response,
                    status: err.response.status,
                };
            } );

        if (response.error) {
            let message = response.error?.data?.message || "Нет доступа к заказу";

            if (response.status === 400) {
                message = 'Ссылка недействительна, сгенерируйте новую ссылку и попробуйте снова';
            }

            Notification( {
                message: message,
                type: NotificationTypes.error,
            } );

            this.props.history.push( '/' );

            return;
        }

        this.setState( {
            isLoading: false,
            order: response,
        } );
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos( type, true );

        const response = await agent.get( `/delegated-orders/${ this.state.orderId }/get-photos-without-authorization?neededType=${ type }`, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( res => res.data.photos )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setLoadingStatusOrderPhotos( type, false );

            Notification( {
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            } );

            return null;
        }

        let newPhotos = [];

        if (response.length > 0) {
            if (Array.isArray( response ) && response.length > 0) {
                newPhotos = [ ...response.map( receipt => window.URL.createObjectURL( new Blob( [ new Uint8Array( receipt.photo.data ).buffer ] ) ) ) ];
            }
        }

        this.setState( { [type]: newPhotos } );

        this.setLoadingStatusOrderPhotos( type, false );

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification( {
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            } );
        }
    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState( { isLoadingProviderPhotos: status } );
        type === 'driverPhotos' && this.setState( { isLoadingDriverPhotos: status } );
        type === 'returnPhotos' && this.setState( { isLoadingReturnPhotos: status } );
    };

    confirmReturn = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить ${ this.state.order.deliveryStatus === 'partialReturn' ? "частичный" : "полный" } возврат?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.confirmReturn.bind( this, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/delegated-orders/${ this.state.orderId }/execute-action`, {}, {
            headers: {
                'x-auth-hash': this.state.hash,
            },
        } )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );
            let message = response.error?.data?.message || "Ошибка при выполнении действия";

            Notification( {
                message: message,
                type: NotificationTypes.error,
            } );

            return;
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );
    };

    // Получение статусов по заказу
    _statusItems = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || "";
        const deliveryMethod = this.state.order?.deliveryMethod || "";

        if (deliveryStatus === "canceled") {
            return [
                {
                    label: "Отменен",
                    active: true,
                    error: true,
                }
            ]
        }

        let orderStatuses = [
            {
                label: "Новый",
                active: true,
                error: false,
            },
            {
                label: "В работе",
                active: [ "inProgress", "partiallyReady", "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Частично готов",
                active: [ "partiallyReady", "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Готов",
                active: [ "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Ожидает погрузки",
                active: [ 'courierInstalled', "loaded", "received", "partialReturn", "fullReturn", "enRoute", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "В пути",
                active: [ "enRoute", "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Доставлен",
                active: [ "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ? "Не выполнен" : "Выполнен" ),
                active: [ "received" ].includes( deliveryStatus ) || ( [ 'partialReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
                error: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
            }
        ];

        return orderStatuses;
    }

    render() {
        const {
            order,
            orderId,
            delegatedId,

            providerPhotos,
            driverPhotos,
            returnPhotos,

            isLoading,

            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,

            isShowBackdrop
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <>
                { isLoading
                    ? <Box>Загрузка...</Box>
                    : <Box>
                        <Box className={ classes.orderTopContent } overflow="hidden">
                            <Box className={ classes.orderTitleWrapper }>
                                <Typography variant="h1" sx={ { whiteSpace: "nowrap" } }>
                                    Заказ №{ orderId }
                                </Typography>
                                <LifeCycleComponent
                                    items={ this._statusItems() }
                                />
                            </Box>
                        </Box>

                        <MainOrderInformation
                            order={ order }
                        />

                        <ProductsOrderInformationComponent
                            order={ order }
                            returnItems={ order?.orderReturn?.returnItems || [] }
                            products={ ( order?.orderItems || [] ) }

                            canBeChanged={ false }
                        />

                        { ( order.attachedPhotosFlags &&
                            (
                                order.attachedPhotosFlags.fromDriver ||
                                order.attachedPhotosFlags.fromProvider
                            )
                        ) && (
                            <OrderPhotosComponent
                                orderDeliveryStatus={ this.state.order?.deliveryStatus || "" }
                                attachedPhotoFlags={ order.attachedPhotosFlags }

                                providerPhotos={ providerPhotos }
                                driverPhotos={ driverPhotos }
                                returnPhotos={ returnPhotos }

                                isLoadingProviderPhotos={ isLoadingProviderPhotos }
                                isLoadingDriverPhotos={ isLoadingDriverPhotos }
                                isLoadingReturnPhotos={ isLoadingReturnPhotos }

                                getPhotos={ this.getOrderPhotosUsingType }
                            />
                        ) }

                        { [ 'filled' ].includes( order.statusOfReturnOrder ) && (
                            <OrderActions
                                order={ order }
                                onConfirmReturn={ this.confirmReturn }
                            />
                        ) }
                    </Box>
                }

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

const styles = {
    orderTopContent: {
        width: "100%",
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: "space-between",
        gap: 16,

        "@media (max-width: 600px)": {
            gap: 8,
        },
    },

    orderTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,

        "@media (max-width: 900px)": {
            flexDirection: 'column',
            alignItems: "flex-start",
        },

        "@media (max-width: 600px)": {
            gap: 8,
        },
    },

};

export default withStyles( styles )( DelegatedOrder );
