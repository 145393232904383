import React from "react";
import { Backdrop, Box, Button, CircularProgress, Container, Drawer, IconButton, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    AccountBalanceWallet as WalletIcon,
    AccountCircle as AccountCircleIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    MenuRounded as MenuIcon
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Link, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import clsx from "clsx";
import { DialogConfirmation, DialogCreateOrder } from "../../../../components";
import agent from "../../../../agent/agent";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import { useSelector } from "react-redux";
import ServiceQRCode from '../../../../assets/svg/sidebar/service-qr-code.svg';
import TelegramQRCode from '../../../../assets/svg/sidebar/telegram-qr-code.svg';

const menus = [
    { label: "Мои заказы", path: "/orders", icon: AssignmentTurnedInIcon },
    { label: "Кошелек", path: "/wallet", icon: WalletIcon },
    { label: "Профиль", path: "/profile", icon: AccountCircleIcon },
];

const Sidebar = () => {
    const isTablet = useMediaQuery( '(max-width:900px)' );
    const refDialogConfirmation = React.useRef( null );
    const classes = useStyles();
    const history = useHistory();
    const user = useSelector( state => state?.global?.user || null );
    const [ isOpenCreateOrder, setIsOpenCreateOrder ] = React.useState( false );
    const [ isShowBackdrop, setIsShowBackdrop ] = React.useState( false );
    const [ isShowSidebar, setIsShowSidebar ] = React.useState( true );

    const isActive = (path) => {
        const activePath = history?.location?.pathname;

        return Boolean( activePath.indexOf( path ) > -1 );
    }

    const createOrder = async (form, isConfirm) => {
        if (!isConfirm) {
            refDialogConfirmation.current.onOpen( {
                title: "Создание заказа",
                message: `Вы действительно хотите создать заказ?"`,
                acceptButtonTitle: "Да, создать",
                acceptButtonAction: createOrder.bind( this, form, true ),
            } );

            return null
        }

        setIsShowBackdrop( true );

        const body = {
            purchaser: {
                phone: user.phone,
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username || "",
                email: user.email || "",
            },
            order: {
                items: form.items.map( item => {
                    return {
                        productId: item.productId,
                        quantity: item.quantityCbm,
                        unit: "cbm",
                    }
                } ),
            },
            delivery: {
                coords: form.coords,
                method: form.method,
                deliveryTimespan: form.deliveryTimespan,
                deliveryDate: form.deliveryDate,
            },
            sourceOfCreation: "personal account",
        };

        const response = await agent.post( `/orders`, body )
            .then( res => res.data.orders )
            .catch( err => {
                return { error: err.response }
            } );


        if (response.error) {
            setIsShowBackdrop( false );

            Notification( {
                message: response.error?.data?.message || "Ошибка при создании заказа",
                type: NotificationTypes.error,
            } );

            return null;
        }

        setIsShowBackdrop( false );

        const orderIds = response.map( item => `№${ item.id }` );
        const notificationMessage = `${ orderIds.length > 1 ? "Заказы" : "Заказ" } ${ orderIds.join( ', ' ) } успешно ${ orderIds.length > 1 ? "созданы" : "создан" }`;
        Notification( {
            message: notificationMessage,
            type: NotificationTypes.success,
        } );

        if (history.location.pathname === '/orders') {
            history.go( 0 );
        }
    };

    const handleCloseCreateOrder = () => {
        setIsOpenCreateOrder( false );
    };

    const onToggleSidebar = React.useCallback( () => {
        setIsShowSidebar( prev => !prev );
    }, [] );

    React.useEffect( () => {
        if (isTablet) {
            setIsShowSidebar( !isTablet );
        }
    }, [ isTablet ] );

    return (
        <>
            { isTablet && (
                <Box className={ classes.header }>
                    <Container>
                        <IconButton
                            onClick={ onToggleSidebar }
                        >
                            <MenuIcon color="primary"/>
                        </IconButton>
                    </Container>
                </Box>
            ) }
            <Drawer
                open={ isShowSidebar }
                variant={ isTablet ? "temporary" : "persistent" }
                className={ classes.root }
                onClose={ isTablet ? onToggleSidebar : null }
            >

                <Box className={ classes.main }>
                    <Link to="/">
                        <Typography className={ classes.mainLabel }>
                            Пилорамы онлайн
                        </Typography>
                    </Link>
                </Box>

                <Box className={ classes.menu }>
                    <Box className={ classes.addButtonWrapper }>
                        <Button
                            className={ classes.addButton }
                            variant="contained"
                            size="small"

                            onClick={ setIsOpenCreateOrder.bind( this, true ) }
                        >
                            Новый заказ
                        </Button>
                    </Box>
                    { menus.map( (itemMenu, index) => (
                        <Link
                            key={ `item-menu-${ index }` }
                            className={ clsx( {
                                [classes.menuItem]: true,
                                [classes.menuItemActive]: isActive( itemMenu.path ),
                            } ) }
                            to={ itemMenu.path }
                        >
                            <itemMenu.icon/>
                            <Typography className={ classes.menuItemLabel }>{ itemMenu.label }</Typography>
                        </Link>
                    ) ) }
                </Box>

                <Box className={ classes.contacts }>
                    <Box mb={ 2 }>
                        <Typography className={ classes.contactLabel }>
                            QR-код на сервис
                        </Typography>
                        <ReactSVG className={ classes.qr_code } src={ ServiceQRCode }/>
                    </Box>
                    <Box mb={ 2 }>
                        <Typography className={ classes.contactLabel }>
                            QR-код на телеграм-бот
                        </Typography>
                        <ReactSVG className={ classes.qr_code } src={ TelegramQRCode }/>
                    </Box>
                    <Box>
                        <Button
                            sx={ {
                                background: "#0088cc",
                                textAlign: "left",
                                '&:hover': {
                                    background: "#0078b7"
                                }
                            } }
                            href="https://t.me/piloramiClientBot"
                            target="_blank"
                            variant="contained"
                        >
                            Перейти в telegram-бот
                            <ReactSVG src={ require( "../../../../assets/svg/socials/telegram.svg" ).default }
                                      style={ { marginLeft: 10 } }/>
                        </Button>
                    </Box>
                    <Box className={ classes.contact }>
                        <Typography className={ classes.contactLabel }>Адрес</Typography>
                        <Typography className={ classes.contactMessage }>
                            г. Екатеринбург, ул. Луначарского, д. 130 - 53
                        </Typography>
                    </Box>
                    <Box className={ classes.contact }>
                        <Typography className={ classes.contactLabel }>Телефон</Typography>
                        <Typography className={ classes.contactMessage }>+7 (904) 549-30-01</Typography>
                    </Box>
                </Box>

                <DialogCreateOrder
                    order={ {} }
                    isOpen={ isOpenCreateOrder }
                    onClose={ handleCloseCreateOrder }
                    onCreate={ createOrder }
                />

                <DialogConfirmation
                    ref={ refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="white"/>
                </Backdrop>
            </Drawer>
        </>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        width: 240,
        position: 'unset',
        '& .MuiDrawer-paper': {
            display: "flex",
            backgroundColor: "#242424",


            width: 240,
            padding: 24,
            boxSizing: "border-box"
        }
    },

    header: {
        width: '100%',
        background: '#242424',
    },

    main: {
        display: "flex",
        alignItems: "center",

        marginBottom: 32
    },
    mainLabel: {
        fontSize: 32,
        lineHeight: "32px",
        fontWeight: "600",
        color: "white"
    },

    menu: {},
    menuItem: {
        display: "flex",
        alignItems: "center",
        position: "relative",

        padding: "12px 0",
        boxSizing: "border-box",
        borderRadius: 0,
        height: 56,

        "& > *": {
            color: "white"
        },
        "&:after": {
            position: "absolute",
            zIndex: -1,
            left: -24,
            top: 0,
            right: -24,
            bottom: 0,
            backgroundColor: "#1A1A1A",

            borderRight: "4px solid #84B92C"
        }
    },
    menuItemActive: {
        "& > *": {
            color: "#84b92c",
            fill: "#84b92c",
        },
        "& *": {
            fill: "#84b92c",
        },
        "&:after": {
            content: "''"
        }
    },
    menuItemIcon: {
        marginRight: 10
    },
    menuItemLabel: {
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: "23px",
        marginLeft: 8
    },

    contacts: {
        marginTop: "auto"
    },
    contact: {
        marginTop: 8
    },
    contactLabel: {
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
    },
    contactMessage: {
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: "600",
        color: "white",
        opacity: 0.8,

        marginTop: 4
    },

    addButtonWrapper: {
        width: "100%",
        height: "max-content",
        display: "flex",
        alignItems: "center",
        marginBottom: 8,
    },
    addButton: {
        '&.MuiButton-root': {
            width: "100%",
            textTransform: "initial",
            fontSize: 14,
            height: "auto",
            borderRadius: 1,
            padding: "10px 10px",
        }
    },

    qr_code: {
        maxWidth: 150,
        width: 150,
        maxHeight: 150,
        height: 150,

        "& > div": {
            maxWidth: 150,
            width: 150,
            maxHeight: 150,
            height: 150,

            "& > svg": {
                maxWidth: 150,
                width: 150,
                maxHeight: 150,
                height: 150,
            },
        },
    }
} ) );

export default Sidebar
