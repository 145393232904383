function setCookie(cookieName, cookieValue, expiryDate) {
    const d = new Date();
    d.setTime( d.getTime() + ( expiryDate * 24 * 60 * 60 * 1000 ) );
    const expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent( document.cookie );
    let ca = decodedCookie.split( ';' );
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt( 0 ) === ' ') {
            c = c.substring( 1 );
        }
        if (c.indexOf( name ) === 0) {
            return c.substring( name.length, c.length );
        }
    }
    return "";
}

function deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; path=/; expires=${new Date(0).toUTCString()}`
}

export {
    setCookie,
    getCookie,
    deleteCookie
};
