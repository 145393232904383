import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { withStyles } from "@mui/styles";

class DialogConfirmation extends React.Component {
    constructor(props) {
        super( props );

        this.state = {
            open: false,

            title: "",
            message: "",
            acceptButtonTitle: "",
            acceptButtonVariant: "",
            cancelButtonTitle: "",
            cancelButtonVariant: "",
            acceptButtonAction: "",
            cancelButtonAction: "",
        }
    }

    onOpen = (props) => {
        this.setState( {
            open: true,

            title: props.title || '',
            message: props.message || '',
            acceptButtonTitle: props.acceptButtonTitle || '',
            acceptButtonVariant: props.acceptButtonVariant || '',
            cancelButtonTitle: props.cancelButtonTitle || '',
            cancelButtonVariant: props.cancelButtonVariant || '',
            acceptButtonAction: props.acceptButtonAction || '',
            cancelButtonAction: props.cancelButtonAction || '',
        } )
    }

    onAccept = () => {
        this.setState( {
            open: false
        } );

        if (!this.state.acceptButtonAction) {
            return null
        }

        this.state.acceptButtonAction();
    }
    onCancel = () => {
        this.setState( {
            open: false
        } );

        if (!this.state.cancelButtonAction) {
            return null
        }

        this.state.cancelButtonAction();
    }

    render() {
        const {
            open,

            title,
            message,
            acceptButtonTitle,
            acceptButtonVariant,
            cancelButtonTitle,
            cancelButtonVariant,
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <Dialog open={ open } fullWidth maxWidth="md" onClose={ this.onCancel }>

                <DialogTitle sx={ { backgroundColor: "#84b92c" } }>
                    <Grid container alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                        <Grid item>
                            <Typography variant="h3" sx={ { color: "white" } }>
                                { title || "Подтверждение" }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={ this.onCancel }>
                                <CloseIcon sx={ { color: "white" } }/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Box py={ 3 }>
                        <Typography variant="subtitle1">
                            { message || "Вы действительно хотите это сделать?" }
                        </Typography>
                    </Box>

                    <DialogActions className={ classes.actions }>
                        <Button
                            variant={ cancelButtonVariant || "outlined" }
                            className={ classes.button }
                            fullWidth

                            onClick={ this.onCancel }
                        >
                            { cancelButtonTitle || "Отменить" }
                        </Button>
                        <Button
                            variant={ acceptButtonVariant || "contained" }
                            className={ classes.button }
                            fullWidth

                            onClick={ this.onAccept }
                        >
                            { acceptButtonTitle || "Применить" }
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

const styles = {
    actions: {
        marginTop: 16,
        padding: 0,

        "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
    },

    button: {
        borderRadius: "4px",
        textTransform: "initial",

        "@media (max-width: 600px)": {
            width: "100%",
            height: 30,
            margin: "0 !important",
        },
    },
};

export default withStyles( styles )( DialogConfirmation )
