import React from 'react';
import { useSelector } from "react-redux";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import { userIsLegal } from "../../../../../../helper/userType";
import { IMaskInput } from "react-imask";
import banks from '../../../../../../constants/banks';
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";

const DialogWithdrawalFunds = (props) => {
    const {
        withdrawalRequest,
        isOpen,
        onClose,
        onWithdrawal,
    } = props;
    const classes = useStyles();
    const user = useSelector( state => state.global?.user || {} );
    const refFormik = React.useRef( null );
    const [ initialValues, setInitialValues ] = React.useState( {
        amount: '',
        bankName: '',
        bank: '',
        needToRememberBankName: true,
        withdrawWholeAvailableSum: false,
    } );

    React.useEffect( () => {
        if (isOpen) {
            if (!userIsLegal( user ) && user.bankNameForWithdrawal && user.bankNameForWithdrawal.length > 0) {
                setInitialValues( {
                    amount: "",
                    bankName: user.bankNameForWithdrawal,
                    bank: {
                        name: user.bankNameForWithdrawal,
                    },
                    needToRememberBankName: true,
                    withdrawWholeAvailableSum: false,
                } );
            } else {
                setInitialValues( {
                    amount: '',
                    bankName: '',
                    bank: '',
                    needToRememberBankName: true,
                    withdrawWholeAvailableSum: false,
                } );
            }
        }
    }, [ isOpen ] );

    const onSubmit = (form) => {
        onWithdrawal( form );
        handleCloseModal();
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues( newForm );
    };

    const handleChangeBankAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        if (value !== null) {
            const { name, id } = value;

            newForm.bank = value;
            newForm.bankName = name;
        } else {
            newForm.bank = "";
            newForm.bankName = "";
        }

        refFormik.current.setValues( newForm );
    };

    const handleChangeBoolean = ({ target }) => {
        const { name, checked } = target;
        const newForm = refFormik.current.values;

        newForm[name] = checked;

        refFormik.current.setValues( newForm );
    }

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="md"
            onClose={ handleCloseModal }
        >
            <DialogTitle>
                <Typography variant="h3">Получить оплату</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    validationSchema={ userIsLegal( user ) ? legalValidationSchema : physicalValidationSchema }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                { withdrawalRequest.length > 0 && (
                                    <Box mt={ 2 } mb={ 2 }>
                                        <Typography variant="h6">
                                            У вас уже был выполнен запрос на вывод средств в
                                            размере <span
                                            style={ { color: '#84b92c' } }>{ withdrawalRequest[withdrawalRequest.length - 1].amount } ₽</span>
                                        </Typography>
                                        { ( values.amount.length > 0 && values.amount !== '0' ) && (
                                            <Typography variant="h6" color="red" mt={ 1 }>
                                                Повторный запрос на вывод средств удалит текущий в размере <span
                                                style={ { color: '#84b92c' } }>{ withdrawalRequest[withdrawalRequest.length - 1].amount } ₽</span> и
                                                создаст новый в размере <span
                                                style={ { color: '#84b92c' } }>{ values.amount } ₽</span>
                                            </Typography>
                                        ) }
                                    </Box>
                                ) }
                                <Box pt={ 1 }>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            value={ values.amount }
                                            error={ Boolean( touched.amount && errors.amount ) }
                                            helperText={ touched.amount && errors.amount }
                                            InputProps={ {
                                                inputComponent: CustomInputAmount
                                            } }
                                            inputProps={ {
                                                scale: 2,
                                            } }
                                            name="amount"
                                            label="Сумма"
                                            placeholder="Введите сумму"
                                            variant="outlined"
                                            disabled={ values.withdrawWholeAvailableSum }

                                            onChange={ handleChange }
                                        />
                                    </Box>

                                    <Box mt={ 2 }>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={ values.withdrawWholeAvailableSum }
                                                    name="withdrawWholeAvailableSum"

                                                    onChange={ handleChangeBoolean }
                                                />
                                            }
                                            label="Все свободные средства"
                                        />
                                    </Box>

                                    { !userIsLegal( user ) && (
                                        <>
                                            <Box mt={ 2 }>
                                                <Autocomplete
                                                    fullWidth
                                                    options={ banks }
                                                    value={ values.bank }
                                                    name="bank"
                                                    noOptionsText='Нет такого банка. Обратитесь к администратору'
                                                    getOptionLabel={ (option) => option.name || option }
                                                    renderInput={ (params) =>
                                                        <TextField
                                                            { ...params }
                                                            label="Банк"
                                                            placeholder='Введите название банка'
                                                            error={ Boolean( touched.bankName && errors.bankName ) }
                                                            helperText={ touched.bankName && errors.bankName }
                                                        />
                                                    }

                                                    onChange={ (e, value) => handleChangeBankAutocomplete( value ) }
                                                />
                                            </Box>

                                            <Box mt={ 2 }>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={ values.needToRememberBankName }
                                                            name="needToRememberBankName"

                                                            onChange={ handleChangeBoolean }
                                                        />
                                                    }
                                                    label="Сохранить банк"
                                                />
                                            </Box>
                                        </>
                                    ) }
                                </Box>

                                <DialogActions className={ classes.actions }>
                                    <Button
                                        variant="outlined"
                                        className={ classes.button }

                                        onClick={ handleCloseModal }
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={ classes.button }

                                        onClick={ handleSubmit }
                                    >
                                        Получить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const CustomInputAmount = ({ inputRef, ...rest }) => (
    <IMaskInput
        ref={ inputRef }
        { ...rest }

        mask={ Number }
        thousandsSeparator=""
        radix="."
        mapToRadix={ [ ',' ] }
        scale={ rest?.scale || 0 }
        unmask={ true }
    />
)

const physicalValidationSchema = Yup.object().shape( {
    amount: Yup.number().when( "withdrawWholeAvailableSum", {
        is: false,
        then: Yup.number().min( 25, 'Минимальное значение 25 ₽' ).required( 'Введите сумму' ),
    } ),
    bankName: Yup.string().required( 'Выберите банк' ),
} );

const legalValidationSchema = Yup.object().shape( {
    amount: Yup.number().when( "withdrawWholeAvailableSum", {
        is: false,
        then: Yup.number().min( 25, 'Минимальное значение 25 ₽' ).required( 'Введите сумму' ),
    } ),
} );

const useStyles = makeStyles( {
    actions: {
        marginTop: 16,
        padding: 0,

        "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
    },

    button: {
        borderRadius: "4px",
        textTransform: "initial",

        "@media (max-width: 600px)": {
            width: "100%",
            height: 30,
            margin: "0 !important",
        },
    },
} );

export default React.memo( DialogWithdrawalFunds );
