import React from "react";
import { Box, Button, Collapse, Grid, Link, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { HelpOutlineRounded as HelpIcon } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import moment from "moment";
import paymentStatus from "../../../../../../constants/paymentStatus";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import { LoadingButton } from "@mui/lab";
import { convertorNumber } from "../../../../../../helper/convertor";
import { formatPhone } from "../../../../../../helper/formatPhone";
import { userIsLegal } from "../../../../../../helper/userType";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import clsx from "clsx";
import { Map } from "../../../../../../components";

const MainOrderInformation = (props) => {
    const {
        user,
        order,
        orderPayment,
        store,
        settings,
        isLoadingPayment,

        orderReceipts,
        isLoadingReceipts,

        setIsOpenTimeChange,
        setIsOpenDeliveryChange,
        onRouteOrderPayment,
        onOrderPaymentAdditional,
        onRoutePaymentPage,
        onGetReceipts,
    } = props;

    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = React.useState( null );
    const [ isOpenReceipts, setIsOpenReceipts ] = React.useState( false );
    const [ isShowMap, setIsShowMap ] = React.useState( false );

    const handleGetReceipts = async (event) => {
        setAnchorEl( event.currentTarget );

        await onGetReceipts();

        setIsOpenReceipts( true );
    };

    const handleCloseReceipts = () => {
        setAnchorEl( null );
        setIsOpenReceipts( false );
    };

    const _getDeliveryMethod = (method) => {
        switch (method) {
            case "delivery": {
                return (
                    <Box className={ classes.delivery }>
                        <img src={ DeliveryIcon }/>
                        Доставка
                    </Box>
                )
            }
            case "pickup":
                return (
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                );
            default:
                return "Не определен";
        }
    }
    const _deliveryDate = () => {
        const deliveryTimes = deliveryTime( settings );
        const date = moment( order?.deliveryDate ).format( "DD.MM.YYYY" );
        const time = deliveryTimes.filter( time => time.value === order.deliveryTimespan );

        return `${ date } (${ time[0]?.label || "Время не указано" })`;
    }
    const _deliveryAddress = () => {
        return order?.deliveryAddress || "Милицейский адрес не найден";
    }

    const _openMap = () => {
        window.open( `https://www.google.ru/maps/search/@${ store.addressLat },${ store.addressLong },12z` )
    }

    const renderPaymentButton = () => {
        if (orderPayment.length > 0) {
            if (!userIsLegal( user )) {
                if (orderPayment[0].onlines.length > 0) {
                    if (orderPayment[0].status === 'canceled') {
                        return (
                            <>
                                <Grid item>
                                    <Typography className={ classes.towValue }>
                                        Оплата не прошла, попробуйте еще раз
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        loading={ isLoadingPayment }
                                        className={ classes.buttonChangeDeliveryDate }

                                        onClick={ onRouteOrderPayment }
                                    >
                                        Оплатить
                                    </LoadingButton>
                                </Grid>
                            </>
                        )
                    }
                    if (orderPayment[0].status === 'processing') {
                        return (
                            <>
                                <Grid item>
                                    <Typography className={ classes.towValue }>
                                        { paymentStatus?.[order.paymentStatus] }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        className={ classes.buttonChangeDeliveryDate }

                                        onClick={ onRoutePaymentPage.bind( this, orderPayment[0].onlines[0].paymentUrl ) }
                                    >
                                        Оплатить
                                    </LoadingButton>
                                </Grid>
                            </>
                        );
                    }
                    return (
                        <>
                            <Grid item>
                                <Link className={ classes.towValue } href={ orderPayment[0].onlines[0].paymentUrl }
                                      target="_blank">
                                    { paymentStatus?.[order.paymentStatus] }
                                </Link>
                            </Grid>
                        </>
                    );
                }
            } else {
                if (orderPayment[0].invoices.length > 0) {
                    if (orderPayment[0].status === 'processing') {
                        return (
                            <>
                                <Grid item>
                                    <Typography className={ classes.towValue }>
                                        { paymentStatus?.[order.paymentStatus] }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        className={ classes.buttonChangeDeliveryDate }

                                        onClick={ onRoutePaymentPage.bind( this, orderPayment[0].invoices[0].documentLink ) }
                                    >
                                        Оплатить
                                    </LoadingButton>
                                </Grid>
                            </>
                        );
                    }
                    return (
                        <>
                            <Grid item>
                                <Typography className={ classes.towValue }>
                                    { paymentStatus?.[order.paymentStatus] }
                                </Typography>
                            </Grid>
                        </>
                    );
                }
            }

            return (
                <Grid item>
                    <Typography className={ classes.towValue }>
                        { paymentStatus?.[order.paymentStatus] }
                    </Typography>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <Typography className={ classes.towValue }>
                        { paymentStatus?.[order.paymentStatus] }
                    </Typography>
                </Grid>
            )
        }
    };

    return (
        <>
            <Box className={classes.root}>

                <Typography className={classes.title}>
                    Основная информация о заказе
                </Typography>

                <Box className={classes.content}>

                    <Box className={classes.row}>
                        <Typography className={classes.towLabel}>Дата/время доставки</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography className={classes.towValue}>
                                    {_deliveryDate()}
                                </Typography>
                            </Grid>
                            {['new'].includes(order?.deliveryStatus) && (
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.buttonChangeDeliveryDate}
                                        onClick={setIsOpenTimeChange.bind(this, true)}
                                    >Перенести время</Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Box className={classes.row}>
                        <Typography className={classes.towLabel}>Способ доставки</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography className={classes.towValue}>
                                    {_getDeliveryMethod(order.deliveryMethod)}
                                </Typography>
                            </Grid>
                            {(['new', 'inProgress', 'partiallyReady', 'readyForShipment', 'notReadyForShipment'].includes(order.deliveryStatus) && !order.deliveryMethodHasBeenChange) && (
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.buttonChangeDeliveryDate}
                                        disabled={order.paymentStatus === 'processing'}

                                        onClick={() => setIsOpenDeliveryChange(true)}
                                    >Изменить</Button>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    {order.deliveryMethod === 'delivery' && (
                        <Box className={classes.row}>
                            <Typography className={classes.towLabel}>Адрес доставки</Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.towValue}>
                                        {_deliveryAddress()}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.buttonChangeDeliveryDate}

                                        onClick={setIsShowMap.bind(this, !isShowMap)}
                                    >
                                        {isShowMap ? "Свернуть" : "Развернуть"} карту
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {(order.refundForDelivery > 0) && (
                        <Box className={classes.row}>
                            <Typography className={classes.towLabel}>
                                Сумма возврата по операциям с доставкой
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.towValue}>
                                        {order.refundForDelivery}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {order.confirmationCode && (
                        <Box className={classes.row}>
                            <Typography className={classes.towLabel}>Код подтверждения</Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography className={classes.towValue}>
                                        {order.confirmationCode}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    <Box className={classes.row}>
                        <Grid container alignItems="center" columnSpacing={1}>
                            <Grid item>
                                <Typography className={classes.towLabel} sx={{marginBottom: "0 !important"}}>Статус
                                    оплаты</Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="После оплаты статус обновится в течение 15 минут" arrow>
                                    <HelpIcon fontSize="inherit" color="primary"/>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' columnSpacing={2.5}>
                            {renderPaymentButton()}
                            {(!userIsLegal(user) && ['new'].includes(order.deliveryStatus)) && (
                                <>
                                    {['new'].includes(order.paymentStatus) && (
                                        <Grid item>
                                            <LoadingButton
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                color="error"
                                                loading={isLoadingPayment}
                                                onClick={onRouteOrderPayment}
                                            >
                                                Оплатить
                                            </LoadingButton>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {!userIsLegal(user) && (
                                <>
                                    {['partialPrepayment'].includes(order.paymentStatus) && (
                                        <Grid item>
                                            <LoadingButton
                                                fullWidth
                                                size="small"
                                                variant="contained"
                                                loading={isLoadingPayment}
                                                className={classes.buttonChangeDeliveryDate}

                                                onClick={onOrderPaymentAdditional}
                                            >
                                                Доплатить
                                            </LoadingButton>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Box>

                    {(order.discount > 0) && (
                        <Box className={classes.row}>
                            <Typography className={clsx({
                                [classes.towLabel]: true,
                                [classes.discountLabel]: true,
                            })}>
                                Скидка исполнителя
                            </Typography>
                            <Typography className={clsx({
                                [classes.towValue]: true,
                                [classes.discountValue]: true,
                            })}>
                                {convertorNumber(order?.discount || 0, 2)} ₽
                            </Typography>
                        </Box>
                    )}

                    {order.adminDiscount > 0 && (
                        <Box className={classes.row}>
                            <Typography className={clsx({
                                [classes.towLabel]: true,
                                [classes.discountLabel]: true,
                            })}>
                                Скидка администратора
                            </Typography>
                            <Typography className={clsx({
                                [classes.towValue]: true,
                                [classes.discountValue]: true,
                            })}>
                                {convertorNumber(order?.adminDiscount || 0, 2)} ₽
                            </Typography>
                        </Box>
                    )}

                    {(order.deliveryMethod === "pickup" && order.deliveryStatus !== "new" && order.store) && (
                        <>
                            <Box className={classes.row}>
                                <Typography className={classes.towLabel}>Склад</Typography>
                                <Typography className={classes.towValue}>
                                    {order?.store?.name || "Склад еще не найден"}
                                </Typography>
                            </Box>
                            <Box className={classes.row}>
                                <Typography className={classes.towLabel}>Адрес склада</Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.towValue}>
                                            {order?.store?.address || '-'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.buttonChangeDeliveryDate}

                                            onClick={setIsShowMap.bind(this, !isShowMap)}
                                        >
                                            {isShowMap ? "Свернуть" : "Развернуть"} карту
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}

                    {(order.paymentStatus !== 'new' && !userIsLegal(user)) && (
                        <Box className={classes.row}>
                            <Typography className={classes.towLabel}>Чеки</Typography>
                            <Box>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        padding: "0 8px",
                                        minHeight: "initial",
                                        height: 30,
                                        borderRadius: 1,
                                    }}
                                    loading={isLoadingReceipts}
                                    id="receipts-basic-button"
                                    aria-controls={isOpenReceipts ? 'receipts-basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isOpenReceipts ? 'true' : undefined}
                                    onClick={handleGetReceipts}
                                >
                                    Скачать
                                </LoadingButton>
                                <Menu
                                    id="receipts-basic-menu"
                                    anchorEl={anchorEl}
                                    open={isOpenReceipts}
                                    onClose={handleCloseReceipts}
                                    MenuListProps={{
                                        'aria-labelledby': 'receipts-basic-button',
                                    }}
                                >
                                    {orderReceipts.length > 0 && (
                                        <>
                                            {orderReceipts.map((receipt, index) => (
                                                <MenuItem key={`order-receipt-index-${index}`}>
                                                    <Button
                                                        disableRipple
                                                        disableFocusRipple
                                                        disableTouchRipple
                                                        disableElevation
                                                        className={classes.menuLinkButton}
                                                        component={Link}
                                                        href={receipt}
                                                        download={`ZAKAZ-№${order.id}-RECEIPT-${index + 1}.pdf`}
                                                    >
                                                        Чек №{index + 1}
                                                    </Button>
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                    {orderReceipts.length <= 0 && (
                                        <MenuItem disabled>
                                            Чеков по этому заказу нет. Попробуйте позже или обратитесь к администратору
                                        </MenuItem>
                                    )}
                                </Menu>
                            </Box>
                        </Box>
                    )}
                </Box>

                {order.deliveryMethod === "delivery" && (
                    <Collapse in={isShowMap} timeout="auto" sx={{marginTop: "20px"}}>
                        <Typography variant="h4" mb={1}>Адрес доставки</Typography>
                        <Box className={classes.content}>
                            <Box className={classes.row} mb={1}>
                                <Typography className={classes.towLabel}>Широта</Typography>
                                <Typography className={classes.towValue}>
                                    {order?.deliveryAddressLat || "-"}
                                </Typography>
                            </Box>

                            <Box className={classes.row}>
                                <Typography className={classes.towLabel}>Долгота</Typography>
                                <Typography className={classes.towValue}>
                                    {order?.deliveryAddressLong || "-"}
                                </Typography>
                            </Box>
                        </Box>
                        <Box height={420}>
                            <Map
                                center={[order?.deliveryAddressLat, order?.deliveryAddressLong]}
                                coords={[order?.deliveryAddressLat, order?.deliveryAddressLong]}
                            />
                        </Box>
                    </Collapse>
                )}

                {(order.deliveryMethod === 'pickup' && order.store) && (
                    <Collapse in={isShowMap} timeout="auto" sx={{marginTop: "20px"}}>
                        <Typography variant="h4" mb={1}>
                            Местоположение склада
                        </Typography>
                        <Box className={classes.content}>
                            <Box className={classes.row} mb={1}>
                                <Typography className={classes.towLabel}>Широта</Typography>
                                <Typography className={classes.towValue}>
                                    {order?.store?.addressLat || "-"}
                                </Typography>
                            </Box>

                            <Box className={classes.row}>
                                <Typography className={classes.towLabel}>Долгота</Typography>
                                <Typography className={classes.towValue}>
                                    {order?.store?.addressLong || "-"}
                                </Typography>
                            </Box>
                        </Box>
                        <Box height={420}>
                            <Map
                                center={[order?.store.addressLat, order?.store.addressLong]}
                                coords={[order?.store.addressLat, order?.store.addressLong]}
                            />
                        </Box>
                    </Collapse>
                )}
            </Box>

            <Box mt={1}/>

            <Box className={classes.root}>

                <Typography className={classes.title}>
                    Контактная информация
                </Typography>

                <Box className={classes.content}>

                    <Box className={classes.row}>
                        <Typography className={classes.towLabel}>Телефон исполнителя</Typography>
                        {order.providerUser !== null
                            ? (
                                <Grid container alignItems="center" columnSpacing={2}>
                                    <Grid item>
                                        <Typography className={classes.towValue}>
                                            <Tooltip title="Позвонить" arrow>
                                                <Link href={`tel:+${order?.providerUser?.phone}`}>
                                                    {formatPhone(order?.providerUser?.phone)}
                                                </Link>
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            href={`https://t.me/+${order?.providerUser?.phone}`}
                                            target="_blank"
                                            variant="contained"
                                            color="primary"
                                            className={classes.buttonChangeDeliveryDate}
                                        >
                                            Написать в телеграм
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                            : (
                                <Typography className={classes.towValue}>
                                    Исполнитель еще не найден
                                </Typography>
                            )
                        }
                    </Box>

                    {order.deliveryMethod === "delivery" && (
                        <Box className={classes.row}>
                            <Typography className={classes.towLabel}>Телефон водителя</Typography>
                            {order.driver !== null
                                ? (
                                    <Grid container alignItems="center" columnSpacing={2}>
                                        <Grid item>
                                            <Typography className={classes.towValue}>
                                                <Tooltip title="Позвонить" arrow>
                                                    <Link href={`tel:+${order?.driver?.phone}`}>
                                                        {formatPhone(order?.driver?.phone)}
                                                    </Link>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                href={`https://t.me/+${order?.driver?.phone}`}
                                                target="_blank"
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonChangeDeliveryDate}
                                            >
                                                Написать в телеграм
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                                : (
                                    <Typography className={classes.towValue}>
                                        Водитель еще не найден
                                    </Typography>
                                )
                            }
                        </Box>
                    )}

                </Box>

            </Box>

        </>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 18
    },
    content: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: -18,

        "& > *": {
            marginTop: 18,
            width: "calc(50%)",

            "@media (max-width: 600px)": {
                marginTop: 9,
                marginBottom: 0,
                width: "100%",
            },
        },

        "@media (max-width: 600px)": {
            marginTop: -9,
            flexDirection: "column",
        },
    },

    row: {},

    rowContent: {
        display: "flex",
        alignItems: 'center',
        gap: 8,
    },

    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,

        marginBottom: 4
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
    },

    discountLabel: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 'normal',
    },
    discountValue: {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "normal",
    },

    buttonChangeDeliveryDate: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white"
    },

    store: {
        marginTop: 60
    },
    storeTitle: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
        fontWeight: "500",
        marginBottom: 16
    },
    storeInfo: {},
    storeInfoTitle: {
        fontSize: 16,
        lineHeight: "16px",
        color: "black",
        opacity: 0.6,
        marginBottom: 8
    },
    storeInfoValue: {
        fontSize: 18,
        lineHeight: "20px",
        color: "black",
        fontWeight: "500"
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    },

    menuLinkButton: {
        fontWeight: 400,
        padding: "0 8px",
        minHeight: "initial",
        height: "auto",
        borderRadius: 1,
        color: "#000000",
    },
} ) );

export default MainOrderInformation
