import React from "react";
import {
    Box,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { convertorNumber, convertorToPiece } from "../../../../../../helper/convertor";
import { numberFormat } from "../../../../../../common/Formater";

const ProductsOrderInformation = (props) => {
    const {
        order,
        returnItems,
        products,

        canBeChanged = true,
        setIsOpenChangeOrderItems
    } = props;
    const classes = useStyles();

    const renderProductQuantity = (product) => {
        const piece = convertorToPiece( product.quantity, product?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( product.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    const renderProductPrices = (product) => {
        return (
            <>
                <Typography>{ product.product.piecePrice } ₽/шт.</Typography>
                <Typography>{ product.product.cbmPrice } ₽/м³</Typography>
            </>
        )
    };

    const getOrderSum = () => {
        if ([ 'partialReturn' ].includes( order.deliveryStatus )) {
            const returnItemsSum = returnItems.reduce( (acc, curr) => +acc + +curr.price, 0 );
            const newSum = +order.sum - +returnItemsSum;

            return `${ convertorNumber( newSum, 2 ) } ₽`
        }
        if ([ 'fullReturn' ].includes( order.deliveryStatus )) {
            return `${ convertorNumber( 0, 2 ) } ₽`;
        }

        return `${ convertorNumber( order.sum, 2 ) } ₽`
    };

    const getOrderTotalPrice = () => {
        if ([ 'partialReturn' ].includes( order.deliveryStatus )) {
            const returnItemsSum = returnItems.reduce( (acc, curr) => +acc + +curr.price, 0 );
            const newTotalPrice = +order?.totalPrice - +returnItemsSum;

            return `${ convertorNumber( newTotalPrice, 2 ) } ₽`;
        }
        if ([ 'fullReturn' ].includes( order.deliveryStatus )) {
            return `${ convertorNumber( 0, 2 ) } ₽`;
        }

        return `${ convertorNumber( order?.totalPrice, 2 ) } ₽`
    };

    const getOrderDeliveryPrice = () => {
        if ([ 'fullReturn' ].includes( order.deliveryStatus )) {
            return `${ convertorNumber( 0, 2 ) } ₽`;
        }

        return `${ convertorNumber( order?.finalDeliveryPrice, 2 ) } ₽`
    };

    return (
        <Box className={ classes.root }>

            <Box className={ classes.head }>
                <Grid container spacing={ 2 } alignItems="center">
                    <Grid item>
                        <Typography className={ classes.title }>
                            Состав заказа
                        </Typography>
                    </Grid>
                    { ( canBeChanged && [ 'new' ].includes( order?.deliveryStatus ) ) && (
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                className={ classes.buttonChangeDeliveryDate }

                                onClick={ setIsOpenChangeOrderItems.bind( this, true ) }
                            >Изменить</Button>
                        </Grid>
                    ) }
                </Grid>
            </Box>

            <TableContainer>
                <Table className={ classes.table }>

                    <TableHead>
                        <TableRow>
                            <TableCell width="25%">Наименование</TableCell>
                            <TableCell>Кол-во</TableCell>
                            <TableCell align="right">Цена</TableCell>
                            <TableCell align="right">Стоимость</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { ( products || [] ).map( (product, index) => (
                            <TableRow key={ `product-table-${ product.id }}-${ index }` }>
                                <TableCell sx={ { whiteSpace: "nowrap" } }>{ product.productName }</TableCell>
                                <TableCell
                                    sx={ { whiteSpace: "nowrap" } }>{ renderProductQuantity( product ) }</TableCell>
                                <TableCell align="right" sx={ { whiteSpace: "nowrap" } }>
                                    { renderProductPrices( product ) }
                                </TableCell>
                                <TableCell align="right" sx={ { whiteSpace: "nowrap" } }>
                                    { convertorNumber( ( product.price ), 2 ) } ₽
                                </TableCell>
                            </TableRow>
                        ) ) }
                    </TableBody>
                </Table>
            </TableContainer>

            { returnItems.length > 0 && (
                <>
                    <TableContainer>
                        <Table className={ classes.tableInfoPrice }>
                            <TableBody>
                                <TableRow>
                                    <TableCell width="70%"/>
                                    <TableCell>
                                        <Typography fontWeight="700" fontSize={ 21 } whiteSpace="nowrap">Стоимость
                                            заказа:</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="700" fontSize={ 21 } whiteSpace="nowrap">
                                            { convertorNumber( order.sum, 2 ) } р.
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography className={ classes.title } pl={ 1 } mt={ 2 }>Возврат</Typography>

                    <TableContainer>
                        <Table className={ classes.table }>

                            <TableHead>
                                <TableRow>
                                    <TableCell>Наименование</TableCell>
                                    <TableCell align="right">Кол-во возврата</TableCell>
                                    <TableCell align="right">Цена</TableCell>
                                    <TableCell align="right">Стоимость</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { ( returnItems || [] ).map( (product, index) => {
                                    return (
                                        <TableRow key={ `return-product-${ product.id }-${ index }` }>
                                            <TableCell>{ product.product.name }</TableCell>
                                            <TableCell align="right" sx={ { whiteSpace: "nowrap" } }>
                                                { renderProductQuantity( product ) }
                                            </TableCell>
                                            <TableCell align="right" sx={ { whiteSpace: "nowrap" } }>
                                                { renderProductPrices( product ) }
                                            </TableCell>
                                            <TableCell align="right" sx={ { whiteSpace: "nowrap" } }>
                                                { convertorNumber( product.price, 2 ) } р.
                                            </TableCell>
                                        </TableRow>
                                    );
                                } ) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) }

            <TableContainer>
                <Table className={ classes.tableInfoPrice }>
                    <TableBody>
                        <TableRow>
                            <TableCell width={ returnItems.length > 0 ? "60%" : "70%" }/>
                            <TableCell>
                                <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                    Стоимость заказа{ returnItems.length > 0 && " после возврата" }:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                    { getOrderSum() }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width={ returnItems.length > 0 ? "60%" : "70%" }/>
                            <TableCell>
                                <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                    Стоимость доставки{ returnItems.length > 0 && " после возврата" }:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                    { getOrderDeliveryPrice() }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        { order.discount > 0 && (
                            <TableRow>
                                <TableCell width={ returnItems.length > 0 ? "50%" : "70%" }/>
                                <TableCell>
                                    <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                        Скидка исполнителя:
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                        { convertorNumber( order?.discount, 2 ) } ₽
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) }
                        { order.adminDiscount > 0 && (
                            <TableRow>
                                <TableCell width={ returnItems.length > 0 ? "50%" : "70%" }/>
                                <TableCell>
                                    <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                        Скидка администратора:
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                        { convertorNumber( order?.adminDiscount, 2 ) } ₽
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) }

                        {(order.refundForDelivery > 0) && (
                            <TableRow>
                                <TableCell width={ returnItems.length > 0 ? "50%" : "70%" }/>
                                <TableCell>
                                    <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                        Сумма возврата по операциям с доставкой:
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography fontWeight="600" fontSize={ 18 } whiteSpace="nowrap">
                                        { convertorNumber( order.refundForDelivery, 2 ) } ₽
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}

                        <TableRow>
                            <TableCell width={ returnItems.length > 0 ? "60%" : "70%" }/>
                            <TableCell>
                                <Typography fontWeight="700" fontSize={ 21 } whiteSpace="nowrap">
                                    Общая стоимость{ returnItems.length > 0 && " после возврата" }:
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography fontWeight="700" fontSize={ 21 } whiteSpace="nowrap">
                                    { getOrderTotalPrice() }
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    );
}

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500"
    },
    table: {
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid rgb(248, 248, 248)",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    },

    tableInfoPrice: {
        "& tr td": {
            fontSize: 14,
            color: "black",
            fontWeight: "400",
            padding: "10px",
            backgroundColor: "white",
        },
    },

    buttonChangeDeliveryDate: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white"
    },
} ) );

export default ProductsOrderInformation
