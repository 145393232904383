import React, { Component } from "react";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import {
    DialogAddBank as DialogAddBankComponent,
    DialogEditBank as DialogEditBankComponent,
    DialogEditUser as DialogEditUserComponent,
    UserInfo as UserInfoComponent,
} from "./components";
import { DialogConfirmation } from "../../../../components";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import {deleteCookie} from "../../../../helper/cookie";

class Profile extends Component {
    constructor(props) {

        super( props );

        this.state = {
            isOpenEditUser: false,
            isOpenAddBank: false,
            isOpenEditBank: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }


    componentDidMount = () => {
    };

    // Логика выхода из аккаунта
    logOutUser = () => {

        deleteCookie('jwtAuthToken');

        this.props.setUser( null );

    }

    // Логика редактирования данных пользователя
    editUser = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите изменить данные?",
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.editUser.bind( this, form, true ),
            } );

            return null;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/users`, form )
            .then( res => res.data.user )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при изменении данных",
                type: NotificationTypes.error,
            } );

            return null;
        }

        this.props.setUser( response );

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Данные успешно изменены",
            type: NotificationTypes.success,
        } );
    };

    // Открытие формы редактирования пользователя
    setIsOpenEditUser = (isOpen) => {
        this.setState( { isOpenEditUser: isOpen } );
    };
    // Закрытие формы редактирования пользователя
    handleCloseEditUser = () => {
        this.setIsOpenEditUser( false );
    };

    // Открытие формы добавления банка (физ лицо)
    setIsOpenAddBank = (isOpen) => {
        this.setState( { isOpenAddBank: isOpen } );
    };
    // Закрытие формы добавления банка
    handleCloseAddBank = () => {
        this.setIsOpenAddBank( false );
    };

    // Открытие формы редактирования банка (физ лицо)
    setIsOpenEditBank = (isOpen) => {
        this.setState( { isOpenEditBank: isOpen } );
    };
    // Закрытие формы редактирования банка
    handleCloseEditBank = () => {
        this.setIsOpenEditBank( false );
    }

    render() {
        const {
            user,
            classes,
        } = this.props;
        const {
            isOpenEditUser,
            isOpenAddBank,
            isOpenEditBank,

            isShowBackdrop
        } = this.state

        return (
            <Box mb={ 2 }>
                <Box mt={ 2 } mb={ 4 }>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1" className={ classes.title }>
                                Профиль
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                endIcon={ <LogoutIcon/> }
                                color="secondary"
                                variant="contained"
                                className={ classes.buttonLogOut }

                                onClick={ this.logOutUser }
                            >
                                Выход
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <UserInfoComponent
                    user={ user }
                    onOpenEditUser={ this.setIsOpenEditUser }
                    onOpenAddBank={ this.setIsOpenAddBank }
                    onOpenEditBank={ this.setIsOpenEditBank }
                />

                <DialogEditUserComponent
                    user={ user }
                    isOpen={ isOpenEditUser }
                    onClose={ this.handleCloseEditUser }
                    onEdit={ this.editUser }
                />

                <DialogAddBankComponent
                    isOpen={ isOpenAddBank }
                    onClose={ this.handleCloseAddBank }
                    onAdd={ this.editUser }
                />

                <DialogEditBankComponent
                    isOpen={ isOpenEditBank }
                    onClose={ this.handleCloseEditBank }
                    onEdit={ this.editUser }
                />

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Box>
        );
    }
}

const styles = {
    title: {
        "@media (max-width: 600px)": {
            fontSize: 24,
            lineHeight: "normal",
        },
    },

    buttonLogOut: {
        height: 40,
        padding: "0 32px",

        "@media (max-width: 600px)": {
            height: 30,
            padding: "0 24px",
        },
    }
}
const StylesProfile = withStyles( styles )( Profile );

export default StylesProfile;
