import React, { useState } from 'react';
import { Box, CircularProgress, Collapse, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ExpandLessRounded as LessIcon, ExpandMoreRounded as MoreIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const OrderPhotos = (props) => {
    const {
        orderDeliveryStatus,
        attachedPhotoFlags,
        providerPhotos,
        driverPhotos,
        returnPhotos,

        isLoadingProviderPhotos,
        isLoadingDriverPhotos,
        isLoadingReturnPhotos,

        getPhotos,
    } = props;
    const classes = useStyles();
    const [ openPhotos, setOpenPhotos ] = useState( '' ); // providerPhotos | driverPhotos | returnPhotos | billOfLadingPhotos

    const handleChangeOpenPhotos = async (type) => {
        if (openPhotos === type) {
            setOpenPhotos( '' );
        } else {
            if (
                ( type === 'providerPhotos' && providerPhotos.length <= 0 ) ||
                ( type === 'driverPhotos' && driverPhotos.length <= 0 ) ||
                ( type === 'returnPhotos' && returnPhotos.length <= 0 )
            ) {
                return await getPhotos( type, () => setOpenPhotos( type ) );
            }

            setOpenPhotos( type );
        }
    };

    const renderPhotos = (type) => {
        const isNotEmptyProviderPhotos = attachedPhotoFlags?.fromProvider || false;
        const isNotEmptyDriverPhotos = attachedPhotoFlags?.fromDriver || false;
        const isNotEmptyReturnPhotos = attachedPhotoFlags?.returnPhotos || false;

        if (type === 'providerPhotos') {
            if (isNotEmptyProviderPhotos) {
                return (
                    <>
                        <Box mb={ 1 } className={ classes.columnContent }>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={ isLoadingProviderPhotos }
                                loadingIndicator={ (
                                    <CircularProgress color="inherit" size={ 32 }/>
                                ) }
                                className={ clsx( {
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'providerPhotos',
                                } ) }
                                endIcon={ openPhotos === 'providerPhotos' ? <LessIcon/> : <MoreIcon/> }

                                onClick={ handleChangeOpenPhotos.bind( this, 'providerPhotos' ) }
                            >
                                Отгрузка со склада
                            </LoadingButton>
                            <Collapse
                                in={ openPhotos === 'providerPhotos' }
                                timeout="auto"
                            >
                                <Box className={ classes.imageWrapper }>
                                    { providerPhotos.map( (photo, i) => (
                                        <img
                                            key={ `photo-provider-${ photo }-${ i }` }
                                            src={ photo || '' }
                                            alt={ `Фото при передаче груза - ${ i + 1 }` }/>
                                    ) ) }
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }

        if (type === 'driverPhotos') {
            if (isNotEmptyDriverPhotos) {
                return (
                    <>
                        <Box mb={ 1 } className={ classes.columnContent }>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={ isLoadingDriverPhotos }
                                loadingIndicator={ (
                                    <CircularProgress color="inherit" size={ 32 }/>
                                ) }
                                className={ clsx( {
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'driverPhotos',
                                } ) }
                                endIcon={ openPhotos === 'driverPhotos' ? <LessIcon/> : <MoreIcon/> }

                                onClick={ handleChangeOpenPhotos.bind( this, 'driverPhotos' ) }
                            >
                                Доставка заказа до заказчика
                            </LoadingButton>
                            <Collapse
                                in={ openPhotos === 'driverPhotos' }
                                timeout="auto"
                            >
                                <Box className={ classes.imageWrapper }>
                                    { driverPhotos.map( (photo, i) => (
                                        <img
                                            key={ `photo-delivery-${ photo }-${ i }` }
                                            src={ photo || '' }
                                            alt={ `Фото при доставке груза до заказчика - ${ i + 1 }` }/>
                                    ) ) }
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }

        if (type === 'returnPhotos') {
            if (isNotEmptyReturnPhotos) {
                return (
                    <>
                        <Box mb={ 1 } className={ classes.columnContent }>
                            <LoadingButton
                                fullWidth
                                size='large'
                                loading={ isLoadingReturnPhotos }
                                loadingIndicator={ (
                                    <CircularProgress color="inherit" size={ 32 }/>
                                ) }
                                className={ clsx( {
                                    [classes.button]: true,
                                    [classes.buttonActive]: openPhotos === 'returnPhotos',
                                } ) }
                                endIcon={ openPhotos === 'returnPhotos' ? <LessIcon/> : <MoreIcon/> }

                                onClick={ handleChangeOpenPhotos.bind( this, 'returnPhotos' ) }
                            >
                                { orderDeliveryStatus === 'partialReturn' ? "Частичный " : "Полный " } возврат
                            </LoadingButton>
                            <Collapse
                                in={ openPhotos === 'returnPhotos' }
                                timeout="auto"
                            >
                                <Box className={ classes.imageWrapper }>
                                    { returnPhotos.map( (photo, i) => (
                                        <img
                                            key={ `photo-return-${ photo }-${ i }` }
                                            src={ photo || '' }
                                            alt={ `Фото при ${ orderDeliveryStatus === 'partialReturn' ? "частичном " : "полном " } возврате - ${ i + 1 }` }/>
                                    ) ) }
                                </Box>
                            </Collapse>
                        </Box>
                    </>
                );
            }
        }
    };

    return (
        <Box className={ classes.root }>

            <Typography className={ classes.title }>
                Фотографии заказа
            </Typography>

            <Box className={ classes.content }>
                { renderPhotos( 'providerPhotos' ) }
                { renderPhotos( 'driverPhotos' ) }
                { renderPhotos( 'returnPhotos' ) }
            </Box>
        </Box>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 18
    },
    content: {
        display: "flex",
        flexDirection: "column",
    },

    columnContent: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
    },

    button: {
        "&.MuiLoadingButton-root": {
            background: "#84b92c1a",
            justifyContent: "space-between",
            fontWeight: 500,
            fontSize: '20px',
            letterSpacing: '0.02em',
            lineHeight: 'normal',
            textTransform: "none",
            color: "#000000",
            borderRadius: 0,
            border: "1px solid #84b92c1a",
        },

        "&:disabled": {
            background: "#c5c5c5",
            border: "1px solid #c5c5c5",
            color: "#c5c5c5",
        },
    },

    buttonActive: {
        "&.MuiLoadingButton-root": {
            borderColor: "#84b92c",
        }
    },

    imageWrapper: {
        maxWidth: "100%",
        padding: 16,
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
        flexWrap: "wrap",
        background: "#84b92c1a",
        border: "1px solid #84b92c",
        borderTop: "none",
        "& > img": {
            maxWidth: "calc(100% / 3)",
            objectFit: "contain",
        }
    },
} )

export default OrderPhotos;
