import React from 'react';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const OrdersTypeButtons = (props) => {
    const {
        ordersTypesButtons,
        ordersType,
        filter,
        onChangeType
    } = props;

    const handleChangeType = (event, newType) => {
        const newFilter = { ...filter };
        newFilter.type = newType;

        onChangeType(newFilter, true);
    };

    return (
        <ToggleButtonGroup
            value={ ordersType }
            exclusive
            onChange={ handleChangeType }
        >
            { ordersTypesButtons.map(button => (
                <ToggleButton key={ `orders-types-button-${ button.id }` } color='primary' value={ button.value }>
                    { button.title }
                </ToggleButton>
            )) }
        </ToggleButtonGroup>
    );
};

export default OrdersTypeButtons;
