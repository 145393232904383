import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { AddRounded as PlusIcon, DeleteRounded as DeleteIcon, RemoveRounded as MinusIcon, } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { deliveryTime } from "../../helper/deliveryTime";
import { convertorToCbm, convertorToPiece } from "../../helper/convertor";
import { numberFormat } from "../../common/Formater";
import { makeStyles } from "@mui/styles";
import { IMaskInput } from "react-imask";
import { AddressSuggestionsComponent } from "../index";
import agent from "../../agent/agent";
import moment from "moment";
import * as Yup from "yup";

const DialogCreateOrder = (props) => {
    const {
        order,
        isOpen,

        onClose,
        onCreate,
    } = props;
    const classes = useStyles();
    const refFormik = React.useRef( null );
    const settings = useSelector( state => state?.global?.settings || null );
    const deliveryTimes = deliveryTime( settings );
    const minDeliveryDate = moment().add( settings.deliveryMinDays, "days" ).format( "YYYY-MM-DD" );
    const maxDeliveryDate = moment().add( settings.deliveryMaxDays, "days" ).format( "YYYY-MM-DD" );
    const [ initialValues, setInitialValues ] = React.useState( {
        items: [],
        coords: [ null, null ],
        method: '',
        deliveryTimespan: '',
        deliveryDate: '',
        address: {
            value: '',
        },
        latitude: "",
        longitude: "",
    } );
    const [ products, setProducts ] = React.useState( [] );

    useEffect( () => {
        return () => {
            if (Object.keys( order ).length > 0 && order !== null) {
                setInitialValues( {
                    items: order.orderItems.map( item => {
                        const piece = convertorToPiece( item.quantity, item?.product?.pieceToCbmConversionFactor || 0 );
                        return {
                            productId: item.product.id,
                            quantityCbm: item.quantity,
                            quantityPiece: piece,
                        }
                    } ),
                    coords: [ +order.deliveryAddressLat, +order.deliveryAddressLong ],
                    method: order.deliveryMethod,
                    deliveryTimespan: order.deliveryTimespan,
                    deliveryDate: order.deliveryDate,
                    address: {
                        value: order.deliveryAddress,
                    },
                    latitude: order.deliveryAddressLat + "",
                    longitude: order.deliveryAddressLong + "",
                } );
            }
        };
    }, [ isOpen ] );

    useEffect( () => {
        ( async () => {
            await getProducts();
        } )();
    }, [] );

    const getProducts = async () => {
        const response = await agent.get( `/products` )
            .then( res => res.data.products )
            .catch( err => [] );

        setProducts( response );
    };

    const onSubmit = (form) => {
        const newForm = {
            ...form,
            deliveryDate: moment( form.deliveryDate ).format( 'YYYY-MM-DD' ),
        };

        if (newForm.method === 'pickup' && ( newForm.coords[0] === null || newForm.coords[1] === null || newForm?.address?.value === null )) {
            delete newForm.coords;
        }

        onCreate( newForm );
        handleCloseModal();
    };

    const handleAddItem = () => {
        const newForm = refFormik.current.values;

        newForm.items = [ ...newForm.items, {
            productId: "",
            quantityPiece: 0,
            quantityCbm: 0,
        } ];

        refFormik.current.setValues( newForm );
    }

    const handleDeleteItem = (idx) => {
        const newForm = refFormik.current.values;
        newForm.items.splice( idx, 1 );

        refFormik.current.setValues( newForm );
    }

    const handleChangeItem = (e, idx) => {
        const { name, value } = e.target;
        const newForm = refFormik.current.values;
        const product = products.find( item => item.id === value );
        const piece = convertorToPiece( 1, product.pieceToCbmConversionFactor || 0 );
        const cbm = convertorToCbm( piece, product.pieceToCbmConversionFactor || 0 );
        newForm.items[idx][name] = value;
        newForm.items[idx].quantityPiece = piece;
        newForm.items[idx].quantityCbm = cbm;

        refFormik.current.setValues( newForm );
    }

    const handleChangePieceQuantity = (idx, value) => {
        const newForm = refFormik.current.values;
        const product = products.find( item => item.id === newForm.items[idx].productId );
        const cbm = convertorToCbm( value, product?.pieceToCbmConversionFactor || 0 );

        newForm.items[idx].quantityPiece = value;
        newForm.items[idx].quantityCbm = cbm;

        refFormik.current.setValues( newForm );
    };

    const handleAddCbmQuantity = (idx) => {
        const newForm = refFormik.current.values;
        const product = products.find( item => item.id === newForm.items[idx].productId );
        const value = product.cbmStep >= 1 ? product.cbmStep : 1;
        const piece = convertorToPiece( value, product?.pieceToCbmConversionFactor || 0 );
        const cbm = +convertorToCbm( piece, product?.pieceToCbmConversionFactor || 0 ) + +newForm.items[idx].quantityCbm;
        const newPiece = convertorToPiece( cbm, product?.pieceToCbmConversionFactor || 0 );

        newForm.items[idx].quantityCbm = numberFormat( cbm, 4, '.' );
        newForm.items[idx].quantityPiece = newPiece;

        refFormik.current.setValues( newForm );
    };

    const handleDecreaseCbmQuantity = (idx) => {
        const newForm = refFormik.current.values;
        const product = products.find( item => item.id === newForm.items[idx].productId );
        const value = product.cbmStep >= 1 ? product.cbmStep : 1;
        const piece = convertorToPiece( value, product?.pieceToCbmConversionFactor || 0 );
        const cbm = +newForm.items[idx].quantityCbm - +convertorToCbm( piece, product?.pieceToCbmConversionFactor || 0 );
        const newPiece = convertorToPiece( cbm, product?.pieceToCbmConversionFactor || 0 );

        newForm.items[idx].quantityCbm = numberFormat( cbm, 4, '.' );
        newForm.items[idx].quantityPiece = newPiece;

        refFormik.current.setValues( newForm );
    };

    const handleChangeAddress = (value, coords) => {
        const newForm = refFormik.current.values;

        newForm.address = { ...value };
        newForm.coords = coords || [ null, null ];
        newForm.latitude = String( coords?.[0] ) || "0";
        newForm.longitude = String( coords?.[1] ) || "0";

        refFormik.current.setValues( newForm );
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues( newForm );
    }

    const resetAddress = () => {
        const newForm = refFormik.current.values;

        newForm.coords = [ null, null ];
        newForm.latitude = '';
        newForm.longitude = '';
        newForm.address = { value: '' };

        refFormik.current.setValues( newForm );
    };

    const handleCloseModal = () => {
        refFormik.current.resetForm();
        onClose();
    };

    return (
        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="md"
            onClose={ handleCloseModal }
        >
            <DialogTitle>
                <Typography variant="h3">Создание заказа</Typography>
            </DialogTitle>

            <DialogContent className={ classes.root }>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    validationSchema={ validationSchema }

                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box>
                                    { values.items.length > 0
                                        ? (
                                            <>
                                                <Box>
                                                    { values.items.map( (item, idx) => (
                                                        <>
                                                            <Box key={ `values-item-${ idx }` }
                                                                 className={ classes.itemWrapper }>

                                                                <FormControl
                                                                    className={ classes.productSelect }
                                                                    fullWidth
                                                                >
                                                                    <InputLabel
                                                                        error={ ( touched.items && errors.items ) ? touched?.items[idx]?.productId && Boolean( errors?.items[idx]?.productId ) : false }
                                                                        id={ `productId-${ item.productId }${ idx }` }
                                                                    >
                                                                        Товар
                                                                    </InputLabel>
                                                                    <Select
                                                                        id={ `productId-${ item.productId }${ idx }` }
                                                                        fullWidth
                                                                        name="productId"
                                                                        value={ item.productId }
                                                                        label="Товар"
                                                                        error={ ( touched.items && errors.items ) ? touched?.items[idx]?.productId && Boolean( errors?.items[idx]?.productId ) : false }

                                                                        onChange={ (e) => handleChangeItem( e, idx ) }
                                                                    >
                                                                        { products.map( product => (
                                                                            <MenuItem key={ `product-${ product.id }` }
                                                                                      value={ product.id }>
                                                                                { product.name }
                                                                            </MenuItem>
                                                                        ) ) }
                                                                    </Select>
                                                                    { ( touched.items && errors.items ) && errors?.items[idx]?.productId && touched?.items[idx]?.productId ? (
                                                                        <FormHelperText error variant="filled">
                                                                            { errors?.items[idx]?.productId }
                                                                        </FormHelperText>
                                                                    ) : null }
                                                                </FormControl>

                                                                <Box className={ classes.columnContent }>
                                                                    <Box className={ classes.rowContent }>
                                                                        <IconButton
                                                                            color="primary"
                                                                            disabled={ +item.quantityPiece - 1 <= 0 || item.productId === '' }

                                                                            onClick={ handleChangePieceQuantity.bind( this, idx, item.quantityPiece - 1 ) }
                                                                        >
                                                                            <MinusIcon/>
                                                                        </IconButton>

                                                                        <Typography>{ item.quantityPiece } шт.</Typography>

                                                                        <IconButton
                                                                            color="primary"
                                                                            disabled={ item.productId === '' }

                                                                            onClick={ handleChangePieceQuantity.bind( this, idx, item.quantityPiece + 1 ) }
                                                                        >
                                                                            <PlusIcon/>
                                                                        </IconButton>
                                                                    </Box>

                                                                    <Box className={ classes.rowContent }>
                                                                        <IconButton
                                                                            color="primary"
                                                                            disabled={ +item.quantityCbm - 1 < 0 || item.productId === '' }

                                                                            onClick={ handleDecreaseCbmQuantity.bind( this, idx ) }
                                                                        >
                                                                            <MinusIcon/>
                                                                        </IconButton>

                                                                        <Typography>{ numberFormat( item.quantityCbm, 4, '.' ) } м³</Typography>

                                                                        <IconButton
                                                                            color="primary"
                                                                            disabled={ item.productId === '' }

                                                                            onClick={ handleAddCbmQuantity.bind( this, idx ) }
                                                                        >
                                                                            <PlusIcon/>
                                                                        </IconButton>
                                                                    </Box>

                                                                    { ( ( touched.items && errors.items ) && touched?.items[idx]?.quantityCbm && errors?.items[idx]?.quantityCbm ) ? (
                                                                        <FormHelperText error variant="filled">
                                                                            { errors?.items[idx]?.quantityCbm }
                                                                        </FormHelperText>
                                                                    ) : null }
                                                                </Box>

                                                                <Tooltip
                                                                    classes={ {
                                                                        popper: classes.deleteTooltip,
                                                                    } }
                                                                    title="Удалить товар"
                                                                >
                                                                    <IconButton
                                                                        className={ classes.deleteBtn }
                                                                        color="error"
                                                                        onClick={ handleDeleteItem.bind( this, idx ) }
                                                                    >
                                                                        <DeleteIcon color="error"/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </>
                                                    ) ) }
                                                    <Button
                                                        className={ classes.addBtn }
                                                        fullWidth
                                                        color="primary"
                                                        variant="contained"

                                                        onClick={ handleAddItem }
                                                    >
                                                        Добавить товар
                                                    </Button>
                                                </Box>
                                            </>
                                        )
                                        : (
                                            <>
                                                <Button
                                                    fullWidth
                                                    color="primary"
                                                    variant="contained"

                                                    onClick={ handleAddItem }
                                                >
                                                    Добавить товар
                                                </Button>
                                            </>
                                        )
                                    }
                                </Box>
                                <Box>
                                    { values.items.length > 0
                                        ? (
                                            <>
                                                <Box mt={ 3 }>
                                                    <Box mb={ 3 }>
                                                        <TextField
                                                            fullWidth
                                                            type="date"
                                                            name="deliveryDate"
                                                            label="Дата доставки"
                                                            InputLabelProps={ {
                                                                shrink: true,
                                                            } }
                                                            InputProps={ {
                                                                inputProps: {
                                                                    min: minDeliveryDate,
                                                                    max: maxDeliveryDate,
                                                                }
                                                            } }
                                                            value={ values.deliveryDate }
                                                            onChange={ handleChange }
                                                            error={ touched.deliveryDate && Boolean( errors.deliveryDate ) }
                                                            helperText={ touched.deliveryDate && errors.deliveryDate }
                                                        />
                                                    </Box>
                                                    <Box mb={ 3 }>
                                                        <FormControl
                                                            fullWidth
                                                            error={ touched.deliveryTime && Boolean( errors.deliveryTime ) }
                                                        >
                                                            <InputLabel
                                                                error={ errors.deliveryTimespan && touched.deliveryTimespan }
                                                            >
                                                                Время доставки
                                                            </InputLabel>
                                                            <Select
                                                                error={ errors.deliveryTimespan && touched.deliveryTimespan }
                                                                name="deliveryTimespan"
                                                                label="Время доставки"
                                                                value={ values.deliveryTimespan }
                                                                onChange={ handleChange }
                                                            >
                                                                { deliveryTimes.map( (time) => (
                                                                    <MenuItem key={ `time-value-${ time.value }` }
                                                                              value={ time.value }>
                                                                        { time.label }
                                                                    </MenuItem> ) ) }
                                                            </Select>
                                                        </FormControl>
                                                        { errors.deliveryTimespan && touched.deliveryTimespan ? (
                                                            <FormHelperText error variant="filled">
                                                                { errors.deliveryTimespan }
                                                            </FormHelperText>
                                                        ) : null }
                                                    </Box>
                                                    <Box mb={ 3 }>
                                                        <FormControl
                                                            fullWidth
                                                            error={ touched.method && Boolean( errors.method ) }
                                                        >
                                                            <InputLabel
                                                                error={ errors.method && touched.method }
                                                            >
                                                                Метод доставки
                                                            </InputLabel>
                                                            <Select
                                                                error={ errors.method && touched.method }
                                                                fullWidth
                                                                name="method"
                                                                value={ values.method }
                                                                label="Метод доставки"

                                                                onChange={ handleChange }
                                                            >
                                                                <MenuItem value='pickup'>Самовывоз</MenuItem>
                                                                <MenuItem value='delivery'>Доставка</MenuItem>
                                                            </Select>
                                                            { errors.method && touched.method ? (
                                                                <FormHelperText error variant="filled">
                                                                    { errors.method }
                                                                </FormHelperText>
                                                            ) : null }
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <AddressSuggestionsComponent
                                                        notRequired={ values.method === 'pickup' }
                                                        addressValue={ values.address }
                                                        coordsValue={ values.coords }
                                                        latitudeValue={ values.latitude }
                                                        longitudeValue={ values.longitude }
                                                        touched={ touched }
                                                        errors={ errors }

                                                        onChangeAddress={ handleChangeAddress }
                                                        onResetAddress={ resetAddress }
                                                    />
                                                </Box>
                                            </>
                                        )
                                        : null
                                    }
                                </Box>
                                { values.items.length > 0 && (
                                    <DialogActions className={ classes.actions }>
                                        <Button
                                            variant="outlined"
                                            className={ classes.button }

                                            onClick={ handleCloseModal }
                                        >
                                            Отменить
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={ classes.button }

                                            onClick={ handleSubmit }
                                        >
                                            Создать заказ
                                        </Button>
                                    </DialogActions>
                                ) }
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const CustomInputAmount = ({ inputRef, ...rest }) => (
    <IMaskInput
        ref={ inputRef }
        { ...rest }

        mask={ Number }
        thousandsSeparator=""
        radix="."
        mapToRadix={ [ ',' ] }
        scale={ rest?.scale || 0 }
        unmask={ true }
    />
)

Yup.addMethod( Yup.array, "tuple", function (schema) {
    if (!this.isType( schema )) Yup.ValidationError();
    return Yup.object( {
        tuple: Yup.array().min( schema.length ).max( schema.length ), ...Object.fromEntries( Object.entries( schema ) ),
    } ).transform( (value, originalValue) => {
        if (!this.isType( originalValue )) Yup.ValidationError();
        return {
            tuple: originalValue, ...Object.fromEntries( Object.entries( originalValue ) ),
        };
    } );
} );

const validationSchema = Yup.object().shape( {
    items: Yup.array(
        Yup.object( {
            productId: Yup.number().required( 'Выберите товар' ),
            quantityCbm: Yup.string().required( 'Введите количество товара' ),
        } )
    ),
    deliveryTimespan: Yup.string().required( 'Выберите время' ),
    deliveryDate: Yup.string().required( 'Выберите дату' ),
    method: Yup.string().required( 'Выберите метод доставки' ),
    coords: Yup.object().when( 'method', {
        is: 'delivery',
        then: Yup.array().tuple( [
            Yup.number().required( "Обязательное поле" )
                .typeError( "Обязательное поле" )
                .min( -90, "Минимальное значение -90" )
                .max( 90, "Максимальное значение 90" ),
            Yup.number().required( "Обязательное поле" )
                .typeError( "Обязательное поле" )
                .min( -180, "Минимальное значение -180" )
                .max( 180, "Максимальное значение 180" )
        ] ),
    } ).nullable(),
    latitude: Yup.string().when( 'method', {
        is: 'delivery',
        then: Yup.string().required( 'Введите широту' ).min( -90, "Минимальное значение -90" ).max( 90, "Максимальное значение 90" ),
    } ),
    longitude: Yup.string().when( 'method', {
        is: 'delivery',
        then: Yup.string().required( 'Введите долготу' ).min( -180, "Минимальное значение -180" ).max( 180, "Максимальное значение 180" ),
    } ),
    address: Yup.object().when('method', {
        is: 'delivery',
        then: Yup.object( {
            value: Yup.string().required( 'Введите адрес' ),
        } ),
    }),
} );

const useStyles = makeStyles( {
    root: {
        "&.MuiDialogContent-root": {
            paddingTop: 20,
        }
    },

    productSelect: {
        minWidth: "150px",
        "&.MuiFormControl-root": {
            flex: 8,
        }
    },

    rowContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    columnContent: {
        flex: 2,
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
    },

    itemWrapper: {
        display: "flex",
        alignItems: "center",
        overflow: 'auto',
        gap: 25,
        marginTop: 20,

        "&:first-child": {
            marginTop: 0,
        }
    },

    deleteTooltip: {
        "&.MuiTooltip-popper": {
            '& .MuiTooltip-tooltip': {
                background: "#e53935",
            }
        }
    },

    deleteBtn: {
        alignSelf: "center",
        marginLeft: "auto",
    },

    addBtn: {
        marginTop: 20,
    },

    actions: {
        marginTop: 16,
        padding: 0,

        "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
    },

    button: {
        borderRadius: "4px",
        textTransform: "initial",

        "@media (max-width: 600px)": {
            width: "100%",
            height: 30,
            margin: "0 !important",
        },
    },
} );

export default React.memo( DialogCreateOrder );
