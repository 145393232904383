const deliveryTime = (settings) => {
    const time = settings.deliveryTimespans.map( setting => {
        return {
            label: `${ setting.from } - ${ setting.to }`,
            value: `${ setting.from }-${ setting.to }`,
        }
    } );

    return time;
}

export {
    deliveryTime
};
