import React from 'react';
import { Notification, NotificationTypes } from "../common/Notification";

export const useCopyToClipboard = (value) => {
    const [ copiedText, setCopiedText ] = React.useState( null );

    const onCopy = async (text) => {
        if (!navigator?.clipboard) {
            console.warn( 'Clipboard not supported' );

            Notification( {
                message: 'Clipboard not supported',
                type: NotificationTypes.error,
            } );

            return false;
        }

        try {
            await navigator.clipboard.writeText( text )
            setCopiedText( text );

            Notification( {
                message: 'Текст успешно скопирован',
                type: NotificationTypes.success,
            } );

            return true
        } catch (error) {
            console.warn( 'Copy failed', error );
            setCopiedText( null );

            Notification( {
                message: 'Ошибка при копировании текста, попробуйте снова',
                type: NotificationTypes.error,
            } );

            return false;
        }
    };

    return [ copiedText, onCopy ];
};
