import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    Popover,
    TextField,
    Typography
} from "@mui/material";
import {
    ArrowForward as ArrowForwardIcon,
    FilterAlt as FilterAltIcon,
    RemoveRedEye as RemoveRedEyeIcon,
    Settings as SettingsIcon
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { palette } from "../../theme/common";
import { Notification, NotificationTypes } from "../../common/Notification";
import clsx from "clsx";

const PageControls = (props) => {
    const {
        visibleColumns,
        favoriteFilters,

        isOpenFilter,

        onOpenFilter,
        onChangeVisibleColumns,
        onSaveFavoriteFilters
    } = props;
    const [ isShowFormFilterFavorites, setShowFormFilterFavorites ] = useState( false );
    const [ formFilterFavorites, setFormFilterFavorites ] = useState( { name: '' } );
    const classes = useStyles();

    // Фунционал изменения видимости колонок в таблице
    const handleOnChangeVisibleColumns = ({ target }, value) => {
        const { name } = target;

        let newVisibleColumns = { ...visibleColumns };

        newVisibleColumns[name].omit = !value;

        onChangeVisibleColumns( newVisibleColumns );
    }

    // Функционал изменения избранных фильтров
    const handleOnChangeFilterFavorites = ({ target }) => {
        const { name, value } = target;

        let newForm = { ...formFilterFavorites };
        newForm[name] = value;

        setFormFilterFavorites( newForm );
    }
    const handleOnSaveFilterFavorites = () => {
        let body = { ...formFilterFavorites };

        if (!body.name) {

            Notification( {
                type: NotificationTypes.error,
                message: "Заполните наименование"
            } )

            return
        }

        body.url = `/products${ window.location.search }`;

        onSaveFavoriteFilters( [ ...favoriteFilters, body ] );
    }
    const handleOpenFilterFavorites = () => {
        setShowFormFilterFavorites( !isShowFormFilterFavorites );
    }

    const _labelVisibleProducts = () => {
        let totalCount = Object.keys( visibleColumns ).length;
        let visibleCount = Object.keys( visibleColumns ).filter( (t) => !Boolean( visibleColumns[t].omit ) ).length

        return `Показано ${ visibleCount } из ${ totalCount }`
    }

    return (
        <Grid container justifyContent="flex-end" spacing={ 2 }>

            { Boolean( false ) && (
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={ onOpenFilter }
                        className={ clsx( {
                            [classes.buttonOutlinedOpen]: isOpenFilter
                        } ) }
                        startIcon={ ( <FilterAltIcon/> ) }
                    >
                        Фильтр
                    </Button>
                </Grid>
            ) }

            { Boolean( false ) && (
                <Grid item>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                        { (popupState) => (
                            <div>
                                <Button
                                    variant="outlined"
                                    startIcon={ ( <RemoveRedEyeIcon/> ) }
                                    className={ clsx( {
                                        [classes.buttonOutlinedOpen]: popupState.isOpen
                                    } ) }
                                    { ...bindTrigger( popupState ) }
                                >
                                    Избранное
                                </Button>
                                <Popover
                                    { ...bindPopover( popupState ) }
                                    anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                                    transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                                    elevation={ 0 }
                                    classes={ { paper: classes.popoverPaper } }
                                >
                                    <Box mt={ 2 } width={ 320 } border={ 1 } borderColor={ palette.primary.main }
                                         bgcolor="white" borderRadius="4px 0 4px 4px" px={ 2 } py={ 1 }>
                                        <Box mb={ 1 }>
                                            <Typography variant="caption">Избранное</Typography>
                                        </Box>
                                        <Box overflow="auto">
                                            <Grid container spacing={ 1 }>

                                                { favoriteFilters.map( (item, idx) => (
                                                    <Grid key={ `favorite-filter-${ item.name }-${ idx }` } item
                                                          xs={ 12 }>
                                                        <Link href={ item.url }>
                                                            <Typography variant="subtitle1"
                                                                        color="primary">{ item.name }</Typography>
                                                        </Link>
                                                    </Grid>
                                                ) ) }

                                                <Grid item xs={ 12 }>
                                                    { Boolean( !isShowFormFilterFavorites ) ? (
                                                        <Button style={ { padding: 0 } } variant="text"
                                                                onClick={ handleOpenFilterFavorites }>Сохранить</Button>
                                                    ) : (
                                                        <FormSaveFavorites
                                                            form={ formFilterFavorites }
                                                            onChange={ handleOnChangeFilterFavorites }
                                                            onSave={ handleOnSaveFilterFavorites }
                                                        />
                                                    ) }
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Popover>
                            </div>
                        ) }
                    </PopupState>
                </Grid>
            ) }

            <Grid item>
                <PopupState variant="popover" popupId="demo-popup-popover">
                    { (popupState) => (
                        <div>
                            <Button
                                variant="outlined"
                                startIcon={ ( <SettingsIcon/> ) }
                                className={ clsx( {
                                    [classes.buttonOutlinedOpen]: popupState.isOpen
                                } ) }
                                { ...bindTrigger( popupState ) }
                            >
                                Колонки
                            </Button>
                            <Popover
                                { ...bindPopover( popupState ) }
                                anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                                transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                                elevation={ 0 }
                                classes={ { paper: classes.popoverPaper } }
                            >
                                <Box mt={ 2 } width={ 540 } border={ 1 } borderColor={ palette.primary.main }
                                     bgcolor="white" borderRadius="4px 0 4px 4px" px={ 2 } py={ 1 }>
                                    <Box mb={ 1 }>
                                        <Typography variant="caption">{ _labelVisibleProducts() }</Typography>
                                    </Box>
                                    <Box maxHeight={ 250 } overflow="auto">
                                        <Grid container spacing={ 1 }>
                                            {
                                                Object.keys( visibleColumns || {} ).map( (column, idx) => (
                                                    <Grid key={ `page-controls-visible-solumb-${ idx }` } item xs={ 4 }>
                                                        <FormControlLabel
                                                            control={ <Checkbox color="primary"/> }
                                                            name={ column }
                                                            label={ visibleColumns[column]?.name }
                                                            value={ visibleColumns[column]?.omit }
                                                            checked={ !visibleColumns[column]?.omit }
                                                            onChange={ handleOnChangeVisibleColumns }
                                                        />
                                                    </Grid>
                                                ) )
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                            </Popover>
                        </div>
                    ) }
                </PopupState>
            </Grid>

        </Grid>
    )
}

const FormSaveFavorites = (props) => {
    const { form, onChange, onSave } = props;

    return (
        <Grid flexDirection="row" alignItems="center" container spacing={ 2 } wrap="nowrap"
              sx={ { paddingTop: '4px' } }>
            <Grid item sx={ { flex: 1 } }>
                <TextField
                    value={ form.name }
                    size="small"
                    name="name"
                    variant="outlined"
                    label="Наименование"
                    fullWidth

                    onChange={ onChange }
                />
            </Grid>
            <Grid item>
                <IconButton onClick={ onSave }>
                    <ArrowForwardIcon/>
                </IconButton>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles( (theme) => ( {
    buttonOutlinedOpen: {
        borderRadius: '4px 4px 0 0',
        borderBottom: '0!important',
        position: 'relative',
        zIndex: 1301,

        '&:after': {
            content: "''",
            position: 'absolute',
            top: '100%',
            width: '100%',
            height: 17,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            backgroundColor: 'white',
        }
    },

    popoverPaper: {
        background: 'transparent'
    }
} ) );

export default PageControls
