const formatPhone = (phone) => {
    let phoneString = ''
    if (typeof phone !== 'string') {
        return phoneString
    }

    const cleaned = ( '' + phone ).replace( /[^0-9]/g, '' )

    if (cleaned?.length === 11) {
        phoneString = `+${ cleaned[0] } (${ cleaned.slice( 1, 4 ) }) ${ cleaned.slice( 4, 7 ) } - ${ cleaned.slice( 7, 9 ) } - ${ cleaned.slice( 9, 11 ) }`
    }
    return phoneString

}

export {
    formatPhone
}
