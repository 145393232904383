import React, { useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import { convertorToCbm, convertorToPiece } from "../../../../../../helper/convertor";
import { Notification, NotificationTypes } from "../../../../../../common/Notification";
import { numberFormat } from "../../../../../../common/Formater";
import { makeStyles } from "@mui/styles";

let timeoutChangeValue = null;

const DialogFormChangeOrderItems = (props) => {
    const {
        order,
        isOpen,
        onClose,
        onChangeOrderItems
    } = props;
    const classes = useStyles();
    const refFormik = useRef( null );
    const initialValues = {
        orderItems: order.orderItems.map( item => {
            const piece = convertorToPiece( item.quantity, item?.product?.pieceToCbmConversionFactor || 0 );
            return {
                ...item,
                newValue: piece,
                newPiece: piece,
                newCbm: item.quantity,
            };
        } ),
    }

    const onSubmit = (form) => {
        const returnItems = form.orderItems.filter( item => item.newValue > 0 );
        if (returnItems.length > 0) {
            const newForm = {
                orderItems: returnItems.map( item => {
                    return {
                        productId: item.product.id,
                        quantity: item.newCbm,
                    }
                } ),
            };
            onChangeOrderItems( newForm );
            handleClose();
        } else {
            Notification( {
                type: NotificationTypes.error,
                message: "Введите количество товара который хотите изменить",
            } );
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleChangePieceInput = (e, index, coefficient) => {
        const { value } = e.target;
        const newForm = refFormik.current.values;

        if (Number( value ) >= 1 && Number( value ) < convertorToPiece( newForm.orderItems[index].quantity, newForm.orderItems[index]?.product?.pieceToCbmConversionFactor || 0 )) {
            clearTimeout( timeoutChangeValue );
            timeoutChangeValue = null;

            const cbm = convertorToCbm( value, coefficient );

            newForm.orderItems[index].newValue = +value;
            newForm.orderItems[index].newCbm = cbm;
            newForm.orderItems[index].newPiece = +value;
        } else {
            clearTimeout( timeoutChangeValue );
            timeoutChangeValue = null;

            Notification( {
                type: NotificationTypes.warning,
                message: `Должно быть меньше минимум на 1шт.`
            } );
            newForm.orderItems[index].newValue = 0;
            newForm.orderItems[index].newPiece = 0;
            newForm.orderItems[index].newCbm = 0;
        }

        refFormik.current.setValues( newForm );

    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece( orderItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( orderItem.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }
            onClose={ handleClose }
        >
            <DialogTitle>
                Изменить состав заказа
            </DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width="40%">Наименование</TableCell>
                                                <TableCell>Кол-во</TableCell>
                                                <TableCell>Кол-во к изменению</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { values.orderItems.map( (val, index) => (
                                                <TableRow key={ `item-${ val.id }` }>
                                                    <TableCell sx={ { whiteSpace: "nowrap" } }>
                                                        { val.productName }
                                                    </TableCell>
                                                    <TableCell sx={ { whiteSpace: "nowrap" } }>
                                                        { renderQuantity( val ) }
                                                    </TableCell>
                                                    <TableCell sx={ { whiteSpace: "nowrap" } }>
                                                        <Grid container spacing={ 2 }>
                                                            <Grid item xs={ 12 }>
                                                                <Grid container alignItems="flex-end"
                                                                      spacing={ 1 }>
                                                                    <Grid item>
                                                                        <TextField
                                                                            sx={ {
                                                                                width: "50px",
                                                                                height: "30px",
                                                                                "& div": {
                                                                                    height: "30px",
                                                                                },
                                                                                "& input": {
                                                                                    padding: "0px 6px",
                                                                                    textAlign: "center",
                                                                                }
                                                                            } }
                                                                            value={ val.newValue }
                                                                            name="newValue"

                                                                            onChange={ (e) => handleChangePieceInput( e, index, val?.product?.pieceToCbmConversionFactor || 0 ) }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="h6" whiteSpace="nowrap">
                                                                            шт.
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={ 12 }>
                                                                <Grid container alignItems="flex-end"
                                                                      spacing={ 1 }>
                                                                    <Grid item>
                                                                        <TextField
                                                                            sx={ {
                                                                                width: "50px",
                                                                                height: "30px",
                                                                                "& div": {
                                                                                    height: "30px",
                                                                                },
                                                                                "& input": {
                                                                                    padding: "0px 6px",
                                                                                    textAlign: "center",
                                                                                }
                                                                            } }
                                                                            disabled
                                                                            value={ val.newCbm }
                                                                            name="newCbm"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="h6" whiteSpace="nowrap">
                                                                            м³
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            ) ) }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <DialogActions className={ classes.actions }>
                                    <Button
                                        variant='outlined'
                                        className={ classes.button }

                                        onClick={ handleClose }
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant='contained'
                                        className={ classes.button }

                                        onClick={ handleSubmit }
                                    >
                                        Изменить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles( {
    actions: {
        marginTop: 16,
        padding: 0,

        "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
    },

    button: {
        borderRadius: "4px",
        textTransform: "initial",

        "@media (max-width: 600px)": {
            width: "100%",
            height: 30,
            margin: "0 !important",
        },
    },
} );

export default DialogFormChangeOrderItems;
