import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Collapse, Grid, Link, Tooltip, Typography } from "@mui/material";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";
import { Map } from "../../../../../../components";
import paymentStatus from "../../../../../../constants/paymentStatus";
import clsx from "clsx";
import { convertorNumber } from "../../../../../../helper/convertor";
import { formatPhone } from "../../../../../../helper/formatPhone";

const MainOrderInformation = (props) => {
    const {
        order
    } = props;

    const classes = useStyles();
    const settings = useSelector( state => state.global.settings );
    const [ isShowMap, setIsShowMap ] = React.useState( false );

    const _deliveryDate = () => {
        const deliveryTimes = deliveryTime( settings );
        const date = moment( order?.deliveryDate ).format( "DD.MM.YYYY" );
        const time = deliveryTimes.filter( time => time.value === order.deliveryTimespan );

        return `${ date } (${ time[0]?.label || "Время не указано" })`;
    }

    const _getDeliveryMethod = (method) => {
        switch (method) {
            case "delivery": {
                return (
                    <Box className={ classes.delivery }>
                        <img src={ DeliveryIcon }/>
                        Доставка
                    </Box>
                )
            }
            case "pickup":
                return (
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                );
            default:
                return "Не определен";
        }
    }

    const _deliveryAddress = () => {
        return order?.deliveryAddress || "Милицейский адрес не найден";
    }

    const renderPaymentButton = () => {
        return (
            <Grid item>
                <Typography className={ classes.towValue }>
                    { paymentStatus?.[order.paymentStatus] }
                </Typography>
            </Grid>
        )
    };

    return (
        <>
            <Box className={ classes.root }>
                <Typography className={ classes.title }>
                    Основная информация о заказе №{ order.id }
                </Typography>

                <Box className={ classes.content }>
                    <Box>
                        <Typography className={ classes.towLabel }>Дата/время доставки</Typography>
                        <Grid container spacing={ 2 } alignItems="center">
                            <Grid item>
                                <Typography className={ classes.towValue }>
                                    { _deliveryDate() }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Typography className={ classes.towLabel }>Способ доставки</Typography>
                        <Grid container spacing={ 2 } alignItems="center">
                            <Grid item>
                                <Typography className={ classes.towValue }>
                                    { _getDeliveryMethod( order.deliveryMethod ) }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    { order.deliveryMethod === 'delivery' && (
                        <Box className={ classes.row }>
                            <Typography className={ classes.towLabel }>Адрес доставки</Typography>
                            <Grid container spacing={ 2 } alignItems="center">
                                <Grid item>
                                    <Typography className={ classes.towValue }>
                                        { _deliveryAddress() }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={ classes.buttonChangeDeliveryDate }

                                        onClick={ setIsShowMap.bind( this, !isShowMap ) }
                                    >
                                        { isShowMap ? "Свернуть" : "Развернуть" } карту
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    ) }

                    { order.confirmationCode && (
                        <Box className={ classes.row }>
                            <Typography className={ classes.towLabel }>Код подтверждения</Typography>
                            <Grid container spacing={ 2 } alignItems="center">
                                <Grid item>
                                    <Typography className={ classes.towValue }>
                                        { order.confirmationCode }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    ) }

                    <Box className={ classes.row }>
                        <Grid container alignItems="center" columnSpacing={ 1 }>
                            <Grid item>
                                <Typography className={ classes.towLabel } sx={ { marginBottom: "0 !important" } }>Статус
                                    оплаты</Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' columnSpacing={ 2.5 }>
                            { renderPaymentButton() }
                        </Grid>
                    </Box>

                    { ( order.discount > 0 ) && (
                        <Box className={ classes.row }>
                            <Typography className={ clsx( {
                                [classes.towLabel]: true,
                                [classes.discountLabel]: true,
                            } ) }>
                                Скидка исполнителя
                            </Typography>
                            <Typography className={ clsx( {
                                [classes.towValue]: true,
                                [classes.discountValue]: true,
                            } ) }>
                                { convertorNumber( order?.discount || 0, 2 ) } ₽
                            </Typography>
                        </Box>
                    ) }

                    { order.adminDiscount > 0 && (
                        <Box className={ classes.row }>
                            <Typography className={ clsx( {
                                [classes.towLabel]: true,
                                [classes.discountLabel]: true,
                            } ) }>
                                Скидка администратора
                            </Typography>
                            <Typography className={ clsx( {
                                [classes.towValue]: true,
                                [classes.discountValue]: true,
                            } ) }>
                                { convertorNumber( order?.adminDiscount || 0, 2 ) } ₽
                            </Typography>
                        </Box>
                    ) }

                    { ( order.deliveryMethod === "pickup" && order.deliveryStatus !== "new" && order.store ) && (
                        <>
                            <Box className={ classes.row }>
                                <Typography className={ classes.towLabel }>Склад</Typography>
                                <Typography className={ classes.towValue }>
                                    { order?.store?.name || "Склад еще не найден" }
                                </Typography>
                            </Box>
                            <Box className={ classes.row }>
                                <Typography className={ classes.towLabel }>Адрес склада</Typography>
                                <Grid container spacing={ 2 } alignItems="center">
                                    <Grid item>
                                        <Typography className={ classes.towValue }>
                                            { order?.store?.address || '-' }
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={ classes.buttonChangeDeliveryDate }

                                            onClick={ setIsShowMap.bind( this, !isShowMap ) }
                                        >
                                            { isShowMap ? "Свернуть" : "Развернуть" } карту
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) }
                </Box>

                { order.deliveryMethod === "delivery" && (
                    <Collapse in={ isShowMap } timeout="auto" sx={ { marginTop: "20px" } }>
                        <Typography variant="h4" mb={ 1 }>Адрес доставки</Typography>
                        <Box className={ classes.content }>
                            <Box className={ classes.row } mb={ 1 }>
                                <Typography className={ classes.towLabel }>Широта</Typography>
                                <Typography className={ classes.towValue }>
                                    { order?.deliveryAddressLat || "-" }
                                </Typography>
                            </Box>

                            <Box className={ classes.row }>
                                <Typography className={ classes.towLabel }>Долгота</Typography>
                                <Typography className={ classes.towValue }>
                                    { order?.deliveryAddressLong || "-" }
                                </Typography>
                            </Box>
                        </Box>
                        <Box height={ 420 }>
                            <Map
                                center={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                                coords={ [ order?.deliveryAddressLat, order?.deliveryAddressLong ] }
                            />
                        </Box>
                    </Collapse>
                ) }
            </Box>


            <Box mt={ 1 }/>

            <Box className={ classes.root }>

                <Typography className={ classes.title }>
                    Контактная информация
                </Typography>

                <Box className={ classes.content }>

                    <Box className={ classes.row }>
                        <Typography className={ classes.towLabel }>Телефон исполнителя</Typography>
                        { order.providerUser !== null
                            ? (
                                <Grid container alignItems="center" columnSpacing={ 2 }>
                                    <Grid item>
                                        <Typography className={ classes.towValue }>
                                            <Tooltip title="Позвонить" arrow>
                                                <Link href={ `tel:+${ order?.providerUser?.phone }` }>
                                                    { formatPhone( order?.providerUser?.phone ) }
                                                </Link>
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            href={ `https://t.me/+${ order?.providerUser?.phone }` }
                                            target="_blank"
                                            variant="contained"
                                            color="primary"
                                            className={ classes.buttonChangeDeliveryDate }
                                        >
                                            Написать в телеграм
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                            : (
                                <Typography className={ classes.towValue }>
                                    Исполнитель еще не найден
                                </Typography>
                            )
                        }
                    </Box>

                    { order.deliveryMethod === "delivery" && (
                        <Box className={ classes.row }>
                            <Typography className={ classes.towLabel }>Телефон водителя</Typography>
                            { order.driver !== null
                                ? (
                                    <Grid container alignItems="center" columnSpacing={ 2 }>
                                        <Grid item>
                                            <Typography className={ classes.towValue }>
                                                <Tooltip title="Позвонить" arrow>
                                                    <Link href={ `tel:+${ order?.driver?.phone }` }>
                                                        { formatPhone( order?.driver?.phone ) }
                                                    </Link>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                href={ `https://t.me/+${ order?.driver?.phone }` }
                                                target="_blank"
                                                variant="contained"
                                                color="primary"
                                                className={ classes.buttonChangeDeliveryDate }
                                            >
                                                Написать в телеграм
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                                : (
                                    <Typography className={ classes.towValue }>
                                        Водитель еще не найден
                                    </Typography>
                                )
                            }
                        </Box>
                    ) }

                </Box>

            </Box>
        </>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {},
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 18
    },

    content: {
        display: "flex",
        flexWrap: "wrap",

        marginTop: -18,

        "& > *": {
            marginTop: 18,
            width: "calc(50%)",

            "@media (max-width: 600px)": {
                marginTop: 9,
                marginBottom: 0,
                width: "100%",
            },
        },

        "@media (max-width: 600px)": {
            marginTop: -9,
            flexDirection: "column",
        },
    },


    towLabel: {
        fontSize: 16,
        lineHeight: "20px",
        color: "black",
        opacity: 0.6,

        marginBottom: 4
    },
    towValue: {
        fontSize: 18,
        lineHeight: "22px",
        color: "black",
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    },

    buttonChangeDeliveryDate: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white"
    },
} );

export default MainOrderInformation;
