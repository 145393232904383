import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setSettings, setUser } from '../states/global';

import MainRoute from "./Main";
import NotAuth from "./NotAuth";
import agent from "../agent/agent";
import urls from "../variables/urls";
import { Notification, NotificationTypes } from "../common/Notification";
import {deleteCookie, getCookie} from "../helper/cookie";

class Router extends React.Component {
    constructor(props) {
        super( props );

        this.state = {
            isLoading: true
        };
    }

    componentDidMount = async () => {
        await this.getUser();
        await this.getSettings();
    }

    getUser = async () => {
        const jwt = getCookie('jwtAuthToken');

        if (!jwt) {
            this.setState( { isLoading: false } );

            return null
        }

        const data = await agent.get( urls.usersMe ).then( (res) => {
            return res.data
        } ).catch( (err) => {
            return null
        } );
        if (!data) {
            deleteCookie('jwtAuthToken');
            this.setState( { isLoading: false } );

            return null
        }

        if (data?.user?.role !== "client") {
            deleteCookie('jwtAuthToken');
            agent.defaults.headers['x-auth-token'] = "";

            Notification( {
                type: NotificationTypes.error,
                message: "У вас нет доступа до данного сервиса, обратитесь к администратору."
            } )

            await this.setState( { isLoading: false } );

            return
        }

        this.props.setUser( data.user );
    }

    getSettings = async () => {
        const response = await agent.get( '/settings' )
            .then( res => res.data.settings )
            .catch( err => null );

        this.props.setSettings( response );

        this.setState( { isLoading: false } );
    };

    render() {
        const { isLoading } = this.state;
        const { user } = this.props.global;

        if (isLoading) {
            return null
        }

        if (!user) {
            return (
                <NotAuth/>
            );
        }

        return (
            <>
                <MainRoute/>
            </>
        )
    }
}

export default compose(
    connect(
        state => ( {
            global: state.global
        } ),
        dispatch => ( {
            setUser: (user) => dispatch( setUser( user ) ),
            setSettings: (settings) => dispatch( setSettings( settings ) ),
        } ),
    ),
)( Router );
