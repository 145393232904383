import React, {Component} from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {OrdersTypeButtons as OrdersTypeButtonsComponent, Table as TableComponent} from "./components";
import agent from "../../../../agent/agent";
import queryString from "query-string";
import {withStyles} from "@mui/styles";
import {withRouter} from 'react-router-dom';
import {compose} from "recompose";
import {connect} from "react-redux";
import {setFirstRender} from "../../../../states/global";


const initFilter = {
    "sortInfo": "-id",
    //---------------------
    "type": "non-archived",
    //---------------------
}

const visibleColumns = [
    {
        id: "id",
        label: "№",
        sortable: true,
        width: 40,
    },
    {
        id: "deliveryDate",
        label: "Срок выполнения",
        sortable: true,
        width: 60,
    },
    {
        id: "deliveryStatus",
        label: "Статус заказа",
        sortable: false,
        width: 140,
    },
    {
        id: "paymentStatus",
        label: "Статус оплаты",
        sortable: false,
        width: 60,
    },
    {
        id: "items",
        label: "Состав заказа",
        sortable: false,
        width: 140,
    },
    {
        id: "quantity",
        label: "Количество",
        sortable: false,
        width: 140,
    },
    {
        id: "sum",
        label: "Стоимость",
        sortable: false,
        width: 60,
    },
    {
        id: "finalDeliveryPrice",
        label: "Стоимость доставки",
        sortable: true,
        width: 60,
    },
    {
        id: "totalPrice",
        label: "Общая стоимость",
        sortable: true,
        width: 60,
    },
    {
        id: "deliveryAddress",
        label: "Адрес доставки",
        sortable: false,
        width: 200,
    }
];

class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],

            filter: {...initFilter},
            pagination: {
                page: 1,
                totalPage: 1,
            },
            isOpenModal: false,
            settings: {},

            isLoadOrders: true,

            needToUpdate: false,
        };
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getSettings();
        await this.getOrders();

        const firstAppRender = this.props.global.isAppFirstRender;
        const queryParams = queryString.parse(window.location.search, {arrayFormat: 'bracket'});
        const hasIsLegalParam = !!queryParams.isLegal;

        if(this.state.orders.length && firstAppRender && !hasIsLegalParam) {
            const orderId = this.state.orders[0].id;
            this.props.history.push(`orders/${orderId}`);
        }

        if(hasIsLegalParam) {
            this.setState({isOpenModal: true})
        }

        this.props.setFirstRender(false);
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            await this.initFilter();
            await this.getSettings();
            await this.getOrders();
        }
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initFilter,
            ...parseSearch
        };

        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }

    // Логика получения списка заказов
    getOrders = async () => {

        this.setState({isLoadOrders: true});
        const filter = this.getFilters();
        const {orders, totalCount} = await agent.get(`/orders${filter}&limit=20&listing=true`).then((res) => {
            return {
                orders: res.data.orders || [],
                totalCount: res.data.count,
            }
        }).catch(() => {
            return {
                orders: [],
                totalCount: 0,
            }
        });

        const pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoadOrders: false,
            orders,
            pagination,
        });

    }

    getSettings = async () => {
        const settings = await agent.get(`/settings`)
            .then((res) => res.data.settings)
            .catch((err) => {
            });

        this.setState({settings});
    }

    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];

        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/orders?${string.join("&")}`);

        return `?${string.join("&")}`
    }

    changeFilter = async (filter, isFastStart) => {
        await this.setState({filter})
        if (!isFastStart) {
            return null;
        }

        await this.getOrders();
    };

    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getOrders();
    }

    // Типы заказов
    ordersTypesButtons = () => {
        return [
            {
                id: 1,
                title: 'Заказы',
                value: 'non-archived',
            },
            {
                id: 2,
                title: 'Архивные',
                value: 'archive',
            },
        ];
    };

    // Логика навигации
    _routePageOrder = (orderId) => {
        this.props.history.push(`/orders/${orderId}`);
    }

    onCloseModal = () => {
        this.setState({isOpenModal: false});

        if(this.state.orders.length) {
            const orderId = this.state.orders[0].id;
            this.props.history.push(`orders/${orderId}`);
        }
    }

    render() {
        const {
            orders,
            filter,
            pagination,
            settings,
            isLoadOrders
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <>
                <Dialog open={this.state.isOpenModal} fullWidth maxWidth='sm' onClose={this.onCloseModal}>
                    <DialogTitle>
                        <Typography variant="h3" align='center'>Счет для оплаты отправлен на почту</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex"
                             justifyContent="center">
                            <Button
                                color="primary"
                                variant="contained"
                                size='large'
                                onClick={this.onCloseModal}
                            >
                                Понятно
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Box mt={2} mb={2}>
                    <Typography variant="h1" className={classes.title}>
                        Мои заказы
                    </Typography>
                    <Box mt={2}>
                        <OrdersTypeButtonsComponent
                            ordersTypesButtons={this.ordersTypesButtons()}
                            filter={filter}
                            ordersType={filter?.type || ''}
                            onChangeType={this.changeFilter}
                        />
                    </Box>
                </Box>

                <TableComponent
                    filter={filter}
                    data={orders}
                    settings={settings}
                    pagination={pagination}
                    visibleColumns={visibleColumns}
                    isLoading={isLoadOrders}

                    onChangePagination={this.changePagination}
                    routePageOrder={this._routePageOrder}
                    onChangeFilter={this.changeFilter}
                />
            </>
        );
    }
}

const styles = {
    title: {
        "@media (max-width: 600px)": {
            fontSize: 24,
            lineHeight: "normal",
        },
    },
};

Orders = withStyles(styles)(Orders);
Orders = withRouter(Orders);

export default compose(
    connect(
        state => ({
            global: state.global
        }),
        dispatch => ({
            setFirstRender: (firstRender) => dispatch(setFirstRender(firstRender))
        }),
    ),
)(Orders);

// export default Orders;
