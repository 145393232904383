export default {
    styleOverrides: {
        root: {
            // "& tr td": {
            //     backgroundColor: "#FBFBFB"
            // },
            "& tr:last-child td:first-child": {
                borderRadius: "0 0 0 6px"
            },
            "& tr:last-child td:last-child": {
                borderRadius: "0 0 6px 0"
            },
            "& td": {
                padding: "20px",
                border: "none",

                fontSize: 14,
                lineHeight: "19px",
                color: "#242424"
            },
        }
    }
}
