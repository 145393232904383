// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Wallet from './Wallet';

export default compose(
    connect(
        state => ( {
            user: state.global?.user || {}
        } ),
        dispatch => ( {} ),
    ),
)( Wallet );
