import React from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import { Formik } from "formik";
import banks from "../../../../../../constants/banks";
import { makeStyles } from "@mui/styles";

const DialogAddBank = (props) => {
    const {
        isOpen,
        onClose,
        onAdd,
    } = props;
    const classes = useStyles();
    const refFormik = React.useRef( null );
    const [ initialValues, setInitialValues ] = React.useState( {
        bank: "",
        bankNameForWithdrawal: "",
    } );

    React.useEffect( () => {
        if (isOpen) {
            setInitialValues( {
                bankNameForWithdrawal: "",
            } );
        }
    }, [ isOpen ] )

    const onSubmit = (form) => {
        const newForm = {
            bankNameForWithdrawal: form.bankNameForWithdrawal,
        };

        onAdd( newForm );
        handleCloseModal();
    };

    const handleChangeBankAutocomplete = (value) => {
        const newForm = refFormik.current.values;

        if (value !== null) {
            const { name, id } = value;
            newForm.bank = value;
            newForm.bankNameForWithdrawal = name;
        } else {
            newForm.bank = "";
            newForm.bankNameForWithdrawal = "";
        }

        refFormik.current.setValues( newForm );
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={ isOpen }
            fullWidth
            maxWidth="md"
            onClose={ handleCloseModal }
        >
            <DialogTitle>
                <Typography variant="h3">Добавление банка</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={ refFormik }
                    initialValues={ initialValues }
                    onSubmit={ onSubmit }
                >
                    { (props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={ 1 }>
                                    <Autocomplete
                                        fullWidth
                                        options={ banks }
                                        value={ values.bank }
                                        name="bank"
                                        noOptionsText='Нет такого банка. Обратитесь к администратору'
                                        getOptionLabel={ (option) => option.name || option }
                                        renderInput={ (params) =>
                                            <TextField
                                                { ...params }
                                                label="Банк"
                                                placeholder='Введите название банка'
                                                error={ Boolean( touched.bankNameForWithdrawal && errors.bankNameForWithdrawal ) }
                                                helperText={ touched.bankNameForWithdrawal && errors.bankNameForWithdrawal }
                                            />
                                        }

                                        onChange={ (e, value) => handleChangeBankAutocomplete( value ) }
                                    />
                                </Box>

                                <DialogActions className={ classes.actions }>
                                    <Button
                                        variant="outlined"
                                        className={ classes.button }

                                        onClick={ handleCloseModal }
                                    >
                                        Отменить
                                    </Button>

                                    <Button
                                        variant="contained"
                                        className={ classes.button }

                                        onClick={ handleSubmit }
                                    >
                                        Добавить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    } }
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles( {
    actions: {
        marginTop: 16,
        padding: 0,

        "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
    },

    button: {
        borderRadius: "4px",
        textTransform: "initial",

        "@media (max-width: 600px)": {
            width: "100%",
            height: 30,
            margin: "0 !important",
        },
    },
} );

export default React.memo( DialogAddBank );
