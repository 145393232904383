import React from 'react';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Skeleton } from "@mui/lab";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useCopyToClipboard } from "../../../../../../hooks/useCopyToClipboard";

const ActiveDelegatedUrls = (props) => {
    const {
        activeDelegatedUrls,
        isLoadingActiveDelegatedUrls,

        onGenerateLink,
        onDeactivateLink
    } = props;
    const classes = useStyles();
    const [ value, onCopy ] = useCopyToClipboard();

    const handleGenerateLink = () => {
        onGenerateLink( false );
    };

    const handleDeactivateLink = (id) => {
        onDeactivateLink( id, false );
    };

    const getLink = (delegatedUrl) => {
        return `${ window.location.origin }/delegated-order?hash=${ delegatedUrl.hash }&orderId=${ delegatedUrl.orderId }&delegatedId=${ delegatedUrl.id }`;
    };

    return (
        <Box className={ classes.root }>
            <Box className={ classes.head }>
                <Typography className={ classes.title }>
                    Активные ссылки для делегирования
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    className={ classes.button }

                    onClick={ handleGenerateLink }
                >
                    Сгенерировать ссылку
                </Button>
            </Box>

            <TableContainer>
                <Table className={ classes.table }>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Ссылка</TableCell>
                            <TableCell width='10%'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { isLoadingActiveDelegatedUrls
                            ? <>
                                { [ 1, 2, 3, 4, 5 ].map( item => (
                                    <React.Fragment key={ `active-delegated-url-skeleton-${ item }` }>
                                        <TableRow>
                                            <TableCell>
                                                <Skeleton></Skeleton>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton></Skeleton>
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton></Skeleton>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ) ) }
                            </>
                            : <>
                                { activeDelegatedUrls.length > 0
                                    ? <>
                                        { activeDelegatedUrls.map( delegatedUrl => (
                                            <React.Fragment key={ `active-delegated-url-${ delegatedUrl.id }` }>
                                                <TableRow>
                                                    <TableCell>
                                                        { delegatedUrl.id }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title='Скопировать текст'>
                                                            <Button
                                                                variant='text'
                                                                color='primary'
                                                                className={ classes.copyTextButton }

                                                                onClick={ () => onCopy( getLink( delegatedUrl ) ) }
                                                            >
                                                                { getLink( delegatedUrl ) }
                                                            </Button>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <IconButton
                                                            color='error'

                                                            onClick={ () => handleDeactivateLink( delegatedUrl.id ) }
                                                        >
                                                            <DeleteIcon color='error'/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ) ) }
                                    </>
                                    : <>
                                        <TableRow>
                                            <TableCell align='center' colSpan={ 3 }>
                                                Нет активных ссылок для делегирования
                                            </TableCell>
                                        </TableRow>
                                    </>
                                }
                            </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const useStyles = makeStyles( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },

    head: {
        display: "flex",
        alignItems: "center",
        gap: '16px',
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500"
    },

    table: {
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid rgb(248, 248, 248)",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    },

    button: {
        height: 24,
        padding: "0 12px",
        borderRadius: 4,

        fontSize: 12,
        lineHeight: "12px",
        fontWeight: "400",
        color: "white"
    },

    copyTextButton: {
        fontSize: 14,
        lineHeight: "normal",
        fontWeight: "400",
    },
} );

export default ActiveDelegatedUrls;
