import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducer';
import loggerMiddleware from './middleware/logger';
import monitorReducerEnhancer from './enhancers/monitorReducer';

const middlewareEnhancer = applyMiddleware( loggerMiddleware, thunkMiddleware )
const composedEnhancers = compose( middlewareEnhancer, monitorReducerEnhancer )

const store = createStore(
    rootReducer,
    undefined,
    composedEnhancers
);

export default store;
