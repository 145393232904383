import React, { Component } from "react";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import {
    ActiveDelegatedUrls,
    DialogChangeOrderItems as DialogChangeOrderItemsComponent,
    DialogConfirmReturn as DialogConfirmReturnComponent,
    DialogDeliveryAddressChange as DialogDeliveryAddressChangeComponent,
    DialogTimeChange as DialogTimeChangeComponent,
    LifeCycle as LifeCycleComponent,
    MainOrderInformation as MainOrderInformationComponent,
    OrderActions as OrderActionsComponent,
    OrderPhotos as OrderPhotosComponent,
    ProductsOrderInformation as ProductsOrderInformationComponent,
} from "./components";
import { DialogConfirmation, DialogCreateOrder } from "../../../../components";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import { withStyles } from "@mui/styles";

class Order extends Component {
    constructor(props) {
        super( props );

        this.state = {
            order: {},
            orderPayment: [],
            store: {},

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],

            activeDelegatedUrls: [],

            returnItems: [],

            orderReceipts: [],

            orderId: props?.match?.params?.orderId,

            settings: {},

            isOpenTimeChange: false,
            isOpenDeliveryAddressChange: false,
            isOpenChangeOrderItems: false,
            isOpenCreateOrder: false,
            isOpenConfirmPartialReturn: false,
            isOpenConfirmFullReturn: false,

            isLoading: true,
            isLoadingPayment: false,
            isLoadingReceipts: false,
            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,
            isLoadingActiveDelegatedUrls: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.getPayments();
        await this.getSettings();
        await this.getReturnItems();
        await this.getActiveDelegatedUrls();
        await this.getOrder();
    };

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (this.props.match?.params?.orderId !== prevProps.match?.params?.orderId) {
            await this.getPayments();
            await this.getOrder();
            await this.getSettings();
            await this.getOrder();
        }
    };

    // Получение заказа
    getOrder = async () => {
        this.setState( { isLoading: true } );

        const orderId = this.state.orderId;

        const order = await agent.get( `/orders/${ orderId }` ).then( (res) => {
            return res.data?.order || {}
        } ).catch( () => {
            return {}
        } );

        await this.setState( {
            order,
            isLoading: false,
        } )
    }

    getSettings = async () => {
        const settings = await agent.get( `/settings` )
            .then( (res) => res.data.settings )
            .catch( (err) => {
            } );

        this.setState( { settings } );
    }

    // Получение возвратов
    getReturnItems = async () => {
        const response = await agent.get( `/returns/${ this.state.orderId }/items` )
            .then( res => res.data.returnItems )
            .catch( err => [] );

        this.setState( { returnItems: response } );
    };

    // Логика получения чеков
    getOrderReceipts = async () => {
        if (this.state.orderReceipts.length <= 0) {
            this.setState( { isLoadingReceipts: true } );

            const response = await agent.get( `orders/${ this.state.orderId }/receipts` )
                .then( res => res.data.receipts )
                .catch( err => {
                    return { error: err.response };
                } );

            if (response.error) {
                this.setState( {
                    isLoadingReceipts: false,
                    orderReceipts: [],
                } );

                Notification( {
                    message: response.error?.data?.message || "Ошибка при загрузке чеков",
                    type: NotificationTypes.error,
                } );

                return null;
            }

            let receiptsUrls = [];

            if (Array.isArray( response ) && response.length > 0) {
                receiptsUrls = [ ...response.map( receipt => window.URL.createObjectURL( new Blob( [ new Uint8Array( receipt.file.data ).buffer ] ) ) ) ];
            }

            this.setState( {
                isLoadingReceipts: false,
                orderReceipts: receiptsUrls,
            } );
        }
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos( type, true );

        const response = await agent.get( `/orders/${ this.state.orderId }/photos?neededType=${ type }` )
            .then( res => res.data.photos )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setLoadingStatusOrderPhotos( type, false );

            Notification( {
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            } );

            return null;
        }

        let newPhotos = [];

        if (response.length > 0) {
            if (Array.isArray( response ) && response.length > 0) {
                newPhotos = [ ...response.map( receipt => window.URL.createObjectURL( new Blob( [ new Uint8Array( receipt.photo.data ).buffer ] ) ) ) ];
            }
        }

        this.setState( { [type]: newPhotos } );

        this.setLoadingStatusOrderPhotos( type, false );

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification( {
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            } );
        }
    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState( { isLoadingProviderPhotos: status } );
        type === 'driverPhotos' && this.setState( { isLoadingDriverPhotos: status } );
        type === 'returnPhotos' && this.setState( { isLoadingReturnPhotos: status } );
    };

    //Создание заказа
    createOrder = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно создать новый заказ?",
                acceptButtonTitle: "Да, создать",
                acceptButtonAction: this.createOrder.bind( this, form, true ),
            } );

            return null;
        }

        const { user } = this.props;

        this.setState( { isShowBackdrop: true } );

        const body = {
            purchaser: {
                phone: user.phone,
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.username || "",
                email: user.email || "",
            },
            order: {
                items: form.items.map( item => {
                    return {
                        productId: item.productId,
                        quantity: item.quantityCbm,
                        unit: "cbm",
                    }
                } ),
            },
            delivery: {
                coords: form.coords,
                method: form.method,
                deliveryTimespan: form.deliveryTimespan,
                deliveryDate: form.deliveryDate,
            },
            sourceOfCreation: "personal account",
        };

        const response = await agent.post( `/orders`, body )
            .then( res => res.data.orders )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка создания заказа",
                type: NotificationTypes.error
            } );

            return null
        }

        await this.setState( {
            isShowBackdrop: false,
            orderId: response[0].id,
        } );

        await this._routePageOrder( response[0].id );

        Notification( {
            message: "Заказ успешно создан",
            type: NotificationTypes.success
        } );

        if (response.length > 1) {
            Notification( {
                message: "Для оперативности выполнения Вашего заказа мы поделили его на несколько заказов.",
                type: NotificationTypes.success
            } );
        }
    };

    //Изменение состава заказа
    changeOrderItems = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите изменить состав заказа?",
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.changeOrderItems.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/orders/${ this.state.orderId }/items`, form )
            .then( (res) => res.data )
            .catch( (err) => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка изменения состава заказа",
                type: NotificationTypes.error
            } );

            return null
        }

        this.setState( { isShowBackdrop: false } );
        await this.getOrder();

        Notification( {
            message: "Состав заказа успешно изменен",
            type: NotificationTypes.success
        } );
    };

    //Перенос места доставки
    changeDeliveryAddress = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите изменить адрес доставки?",
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.changeDeliveryAddress.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/orders/${ this.state.orderId }/delivery-method`, form )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка изменения адреса доставки",
                type: NotificationTypes.error
            } );

            return null
        }

        await this.getOrder();

        this.setState( { isShowBackdrop: false } );

        Notification( {
            message: "Адрес доставки успешно изменено",
            type: NotificationTypes.success
        } );
    };

    // Перенос времени доставки
    changeDeliveryDate = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите изменить время доставки?",
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.changeDeliveryDate.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/orders/${ this.state.orderId }/delivery-date`, form )
            .then( (res) => res.data )
            .catch( (err) => {
                return { error: err.response }
            } )

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: "Ошибка изменения времени доставки",
                type: NotificationTypes.error
            } );

            return null
        }

        this.setState( { isShowBackdrop: false } );
        await this.getOrder();

        Notification( {
            message: "Время доставки успешно изменено",
            type: NotificationTypes.success
        } );
    }

    // Отмена заказа
    cancelOrder = async (isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: "Вы действительно хотите отменить заказ?",
                cancelButtonTitle: "Нет, не отменять",
                cancelButtonVariant: "contained",
                acceptButtonTitle: "Да, отменить заказ",
                acceptButtonVariant: "outlined",
                acceptButtonAction: this.cancelOrder.bind( this, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/orders/${ this.state.order.id }/cancel` ).then( (res) => {
            return res.data
        } ).catch( (err) => {
            return { error: err.response }
        } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } )

            Notification( {
                message: "Ошибка отмены заказа, попробуйте позднее",
                type: NotificationTypes.error
            } );

            return
        }

        await this.getOrder()

        this.refDialogConfirmation.current.onOpen( {
            title: "Повторить заказ",
            message: "Уважаемый покупатель, предлагаем Вам повторить заказ",
            cancelButtonTitle: "Вернуться в список заказов",
            acceptButtonTitle: "Сделать заказ",
            acceptButtonAction: this.setIsOpenCreateOrder.bind( this, true ),
            cancelButtonAction: this._routePageOrders.bind( this ),
        } );

        this.setState( { isShowBackdrop: false } )

        Notification( {
            message: "Заказ успешно отменен",
            type: NotificationTypes.success
        } );

    }

    // Получение статусов по заказу
    _statusItems = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || "";
        const deliveryMethod = this.state.order?.deliveryMethod || "";

        if (deliveryStatus === "canceled") {
            return [
                {
                    label: "Отменен",
                    active: true,
                    error: true,
                }
            ]
        }

        let orderStatuses = [
            {
                label: "Новый",
                active: true,
                error: false,
            },
            {
                label: "В работе",
                active: [ "inProgress", "partiallyReady", "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Частично готов",
                active: [ "partiallyReady", "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Готов",
                active: [ "readyForShipment", "dutyWarehouse", 'courierInstalled', "loaded", "enRoute", "received", "partialReturn", "fullReturn", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Ожидает погрузки",
                active: [ 'courierInstalled', "loaded", "received", "partialReturn", "fullReturn", "enRoute", 'transferringToDelivery', 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "В пути",
                active: [ "enRoute", "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: "Доставлен",
                active: [ "received", "partialReturn", "fullReturn", 'transferringToClient' ].includes( deliveryStatus ),
                error: false,
            },
            {
                label: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ? "Не выполнен" : "Выполнен" ),
                active: [ "received" ].includes( deliveryStatus ) || ( [ 'partialReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
                error: ( [ 'fullReturn' ].includes( deliveryStatus ) && [ 'received' ].includes( this.state.order.statusOfReturnOrder ) ),
            }
        ];

        return orderStatuses;
    }

    // Получение ссылки на Ю-Кассу
    getPayments = async () => {
        const { payments } = await agent.get( `payments?orderId=${ this.state.orderId }` )
            .then( res => res.data )
            .catch( err => [] );
        if (payments.length > 0) {
            return this.setState( {
                orderPayment: [ payments.pop() ],
            } );
        } else {
            return this.setState( {
                orderPayment: payments,
            } );
        }
    }

    //Подтверждение возврата
    returnConfirm = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить ${ this.state.order.deliveryStatus === 'partialReturn' ? "частичный" : "полный" } возврат?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.returnConfirm.bind( this, form, true ),
            } );

            return
        }

        this.setState( { isShowBackdrop: true } )

        const response = await agent.put( `/returns/${ this.state.orderId }/return-confirmation`, {
            deliveredProductIds: [ ...form ],
        } )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );
        if (response.error) {
            this.setState( { isShowBackdrop: false } )

            Notification( {
                message: response.error.data.message || "Ошибка подтверждения частичного возврата",
                type: NotificationTypes.error
            } );

            return
        }

        await this.getOrder()

        this.setState( { isShowBackdrop: false } )

        Notification( {
            message: "Возврат успешно подтвержден",
            type: NotificationTypes.success,
        } );
    };

    //Оплата заказа
    orderPayment = async () => {
        const {
            order
        } = this.state;

        await this.setState( {
            isLoadingPayment: true,
        } );

        const body = {
            orderId: order.id,
            type: "online",
            returnUrlForOnlinePayment: `https://client.pilorami.online/orders/${ this.state.orderId }`,
        };

        const response = await agent.post( `payments`, body )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            await this.setState( {
                isLoadingPayment: false,
            } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при оплате",
                type: NotificationTypes.error,
            } );

            return null;
        }

        await this.setState( {
            isLoadingPayment: false,
        } );

        this._routePaymentPage( response.online.paymentUrl );
    };
    // Доплата заказа
    orderPaymentAdditional = async () => {
        const {
            order
        } = this.state;

        await this.setState( {
            isLoadingPayment: true,
        } );

        const body = {
            orderId: order.id,
            returnUrlForOnlinePayment: `https://client.pilorami.online/orders/${ this.state.orderId }`,
        }

        const response = await agent.post( `payments/additional`, body )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            this.setState( {
                isLoadingPayment: false,
            } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при доплате",
                type: NotificationTypes.error,
            } );

            return null;
        }

        await this.setState( {
            isLoadingPayment: false,
        } );

        this._routePaymentPage( response.online.paymentUrl );
    };

    // Логика делегирования заказа
    getActiveDelegatedUrls = async () => {
        this.setState( { isLoadingActiveDelegatedUrls: true } );

        const response = await agent.get( `/delegated-orders/${ this.state.orderId }/active-delegated-orders-by-orderId` )
            .then( (res) => {
                return res.data.delegatedOrdersWithActionUrl;
            } )
            .catch( (err) => {
                return [];
            } );

        response.sort( (a, b) => b.id - a.id );

        this.setState( {
            isLoadingActiveDelegatedUrls: false,
            activeDelegatedUrls: response,
        } );
    };

    generateDelegatedUrl = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы точно хотите сгенерировать ссылку для делегирования?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, сгенерировать",
                acceptButtonAction: this.generateDelegatedUrl.bind( this, true ),
            } );

            return;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.post( `/delegated-orders`, {
            orderId: +this.state.orderId,
        } )
            .then( (res) => {
                console.log( "res.data: ", res.data );

                return res.data.hash;
            } )
            .catch( (err) => {
                return {
                    error: err.response,
                }
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || 'Ошибка при генерации ссылки',
                type: NotificationTypes.error,
            } );

            return;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getActiveDelegatedUrls();

        Notification( {
            message: "Ссылка для делегирования успешно сгенерирована",
            type: NotificationTypes.success,
        } );
    };

    deactivateActiveDelegatedUrl = async (delegatedUrlId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen( {
                title: "Подтверждение",
                message: `Вы точно хотите деактивировать ссылку для делегирования?`,
                cancelButtonTitle: "Отменить",
                acceptButtonTitle: "Да, деактивировать",
                acceptButtonAction: this.deactivateActiveDelegatedUrl.bind( this, delegatedUrlId, true ),
            } );

            return;
        }

        this.setState( { isShowBackdrop: true } );

        const response = await agent.put( `/delegated-orders/${ delegatedUrlId }/deactivate` )
            .then( (res) => res.data )
            .catch( (err) => {
                return {
                    error: err.response,
                };
            } );

        if (response.error) {
            this.setState( { isShowBackdrop: false } );

            Notification( {
                message: response.error?.data?.message || "Ошибка при деактивации ссылки для делегирования",
                type: NotificationTypes.error,
            } );

            return;
        }

        this.setState( { isShowBackdrop: false } );
        await this.getActiveDelegatedUrls();

        Notification( {
            message: "Ссылка для делегирования успешно деактивирована",
            type: NotificationTypes.success,
        } );

    };

    _routePaymentPage = (paymentUrl) => {
        window.location.href = paymentUrl;
    }

    _isShowButtonCancelOrder = () => {
        const deliveryStatus = this.state.order?.deliveryStatus || "";

        return [
            "new",
            'inProgress',
            'partiallyReady',
            'readyForShipment',
            'notReadyForShipment'
        ].includes( deliveryStatus );
    }
    _isShowButtonRepeatOrder = () => {
        return true
    }

    _routePageOrder = (orderId) => {
        this.props.history.push( `/orders/${ orderId }` );
    }
    _routePageOrders = () => {
        this.props.history.push( `/orders` );
    };

    // Открытие формы изменения времени и даты
    setIsOpenTimeChange = (isOpen) => {
        this.setState( { isOpenTimeChange: isOpen } );
    };
    // Закрытие формы изменения времени и даты
    handleCloseTimeChange = () => {
        this.setIsOpenTimeChange( false );
    };

    // Открытие формы изменения адреса доставки
    setIsOpenDeliveryAddressChange = (isOpen) => {
        this.setState( { isOpenDeliveryAddressChange: isOpen } );
    };
    // Закрытие формы изменения адреса доставки
    handleCloseDeliveryAddressChange = () => {
        this.setIsOpenDeliveryAddressChange( false );
    };

    //Открыти формы создания заказа
    setIsOpenCreateOrder = (isOpen) => {
        this.setState( {
            isOpenCreateOrder: isOpen,
        } )
    }
    //Закрытие формы создания заказа
    handleCloseCreateOrder = () => {
        this.setIsOpenCreateOrder( false );
    }

    //Открытие формы подтверждения частичного возврата
    setIsOpenConfirmReturn = (isOpen) => {
        this.setState( {
            isOpenConfirmPartialReturn: isOpen,
        } )
    };
    //Закрытие формы подтверждения частичного возврата
    handleCloseConfirmReturn = () => {
        this.setIsOpenConfirmReturn( false );
    }

    // Открытие формы изменения состава заказа
    setIsOpenChangeOrderItems = (isOpen) => {
        this.setState( {
            isOpenChangeOrderItems: isOpen,
        } );
    };
    // закрытие формы изменения состава заказа
    handleCloseChangeOrderItems = () => {
        this.setIsOpenChangeOrderItems( false );
    };

    render() {
        const {
            order,
            orderPayment,
            store,
            orderId,
            settings,

            activeDelegatedUrls,

            providerPhotos,
            driverPhotos,
            returnPhotos,

            returnItems,

            orderReceipts,

            isOpenTimeChange,
            isOpenDeliveryAddressChange,
            isOpenChangeOrderItems,
            isOpenCreateOrder,
            isOpenConfirmPartialReturn,

            isLoading,
            isLoadingPayment,
            isLoadingReceipts,
            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,
            isLoadingActiveDelegatedUrls,

            isShowBackdrop
        } = this.state;
        const {
            classes
        } = this.props;

        return (
            <>

                <Box className={ classes.orderTopContent } overflow="hidden">
                    <Box className={ classes.orderTitleWrapper }>
                        <Typography variant="h1" sx={ { whiteSpace: "nowrap" } }>
                            Заказ №{ orderId }
                        </Typography>
                        <LifeCycleComponent
                            items={ this._statusItems() }
                        />
                    </Box>
                    <Box className={ classes.orderTopActions }>
                        { this._isShowButtonCancelOrder() && <>
                            <Button
                                variant="contained"
                                color="error"
                                className={ classes.orderActionButton }
                                onClick={ () => this.cancelOrder( false ) }
                            >
                                Отменить заказ
                            </Button>
                        </> }
                        { this._isShowButtonRepeatOrder() && (
                            <Button
                                variant="contained"
                                color="primary"
                                className={ classes.orderActionButton }

                                onClick={ this.setIsOpenCreateOrder.bind( this, true ) }
                            >
                                Повторить заказ
                            </Button>
                        ) }
                    </Box>
                </Box>

                { !isLoading && (
                    <>
                        <MainOrderInformationComponent
                            user={ this.props.user }
                            order={ order }
                            orderReceipts={ orderReceipts }
                            orderPayment={ orderPayment }
                            store={ store }
                            settings={ settings }
                            isLoadingPayment={ isLoadingPayment }
                            isLoadingReceipts={ isLoadingReceipts }

                            setIsOpenTimeChange={ this.setIsOpenTimeChange }
                            setIsOpenDeliveryChange={ this.setIsOpenDeliveryAddressChange }
                            onRouteOrderPayment={ this.orderPayment }
                            onOrderPaymentAdditional={ this.orderPaymentAdditional }
                            onRoutePaymentPage={ this._routePaymentPage }
                            onGetReceipts={ this.getOrderReceipts }
                        />

                        <ProductsOrderInformationComponent
                            order={ order }
                            returnItems={ returnItems }
                            products={ ( order?.orderItems || [] ) }

                            setIsOpenChangeOrderItems={ this.setIsOpenChangeOrderItems }
                        />

                        { ( order.attachedPhotosFlags &&
                            (
                                order.attachedPhotosFlags.fromDriver ||
                                order.attachedPhotosFlags.fromProvider
                            )
                        ) && (
                            <OrderPhotosComponent
                                orderDeliveryStatus={ this.state.order?.deliveryStatus || "" }
                                attachedPhotoFlags={ order.attachedPhotosFlags }

                                providerPhotos={ providerPhotos }
                                driverPhotos={ driverPhotos }
                                returnPhotos={ returnPhotos }

                                isLoadingProviderPhotos={ isLoadingProviderPhotos }
                                isLoadingDriverPhotos={ isLoadingDriverPhotos }
                                isLoadingReturnPhotos={ isLoadingReturnPhotos }

                                getPhotos={ this.getOrderPhotosUsingType }
                            />
                        ) }

                        { [ 'partialReturn', 'fullReturn' ].includes( order.deliveryStatus ) && order.statusOfReturnOrder === 'filled' && (
                            <OrderActionsComponent
                                order={ order }
                                setIsOpenReturn={ this.setIsOpenConfirmReturn }
                            />
                        ) }

                        <ActiveDelegatedUrls
                            activeDelegatedUrls={ activeDelegatedUrls }
                            isLoadingActiveDelegatedUrls={ isLoadingActiveDelegatedUrls }

                            onGenerateLink={ this.generateDelegatedUrl }
                            onDeactivateLink={ this.deactivateActiveDelegatedUrl }
                        />

                        <DialogTimeChangeComponent
                            order={ order }
                            isOpen={ isOpenTimeChange }
                            onClose={ this.handleCloseTimeChange }
                            onChange={ this.changeDeliveryDate }
                        />

                        <DialogDeliveryAddressChangeComponent
                            order={ order }
                            isOpen={ isOpenDeliveryAddressChange }
                            onClose={ this.handleCloseDeliveryAddressChange }
                            onChange={ this.changeDeliveryAddress }
                        />

                        <DialogChangeOrderItemsComponent
                            order={ order }
                            isOpen={ isOpenChangeOrderItems }
                            onClose={ this.handleCloseChangeOrderItems }
                            onChangeOrderItems={ this.changeOrderItems }
                        />

                        <DialogCreateOrder
                            order={ order }
                            isOpen={ isOpenCreateOrder }

                            onClose={ this.handleCloseCreateOrder }
                            onCreate={ this.createOrder }
                        />

                        <DialogConfirmReturnComponent
                            order={ order || [] }
                            returnItems={ returnItems || [] }
                            isOpen={ isOpenConfirmPartialReturn }
                            onClose={ this.handleCloseConfirmReturn }
                            onConfirm={ this.returnConfirm }
                        />
                    </>
                ) }

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="white"/>
                </Backdrop>

            </>
        );
    }
}

const styles = {
    orderTopContent: {
        width: "100%",
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: "space-between",
        gap: 16,

        "@media (max-width: 600px)": {
            gap: 8,
        },
    },

    orderTopActions: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,

        "@media (max-width: 900px)": {
            width: "100%",
        },

        "@media (max-width: 600px)": {
            flexDirection: "column",
            gap: 8,
        },
    },

    orderTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,

        "@media (max-width: 900px)": {
            flexDirection: 'column',
            alignItems: "flex-start",
        },

        "@media (max-width: 600px)": {
            gap: 8,
        },
    },

    orderActionButton: {
        width: "100%",
        maxWidth: "160px",
        height: 40,
        borderRadius: 0,

        "@media (max-width: 900px)": {
            maxWidth: "100%",
            height: 30,
        },
    },

    disabledActionButton: {
        background: "gray",
    },
};

export default withStyles( styles )( Order )
