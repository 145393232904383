const UPDATE_USER = 'app/UPDATE_USER'
const SET_SETTINGS = 'app/SET_SETTINGS';
const SET_FIRSTRENDER = 'app/SET_FIRSTRENDER';

const initialState = {
    user: null,
    settings: null,
    isAppFirstRender: true
};

export function setUser(user) {
    return {
        type: UPDATE_USER,
        user
    };
}

export function setSettings(settings) {
    return {
        type: SET_SETTINGS,
        settings,
    }
}

export function setFirstRender(firstRender) {
    return {
        type: SET_FIRSTRENDER,
        firstRender,
    }
}

export default function AppState(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_USER: {
            let user = action.user

            return {
                ...state,
                user
            }
        }
        case SET_SETTINGS: {
            let settings = action.settings;

            return {
                ...state,
                settings,
            };
        }

        case SET_FIRSTRENDER: {
            let isAppFirstRender = action.firstRender;

            return {
                ...state,
                isAppFirstRender
            };
        }
        default:
            return state;
    }
}
