import { palette } from "../common";

export default {
    styleOverrides: {
        root: {
            "& tr > th": {
                backgroundColor: palette.primary.main,

                fontSize: 16,
                lineHeight: "22px",
                color: "white",
                fontWeight: "bold"
            },
            "& tr > th:first-child": {
                borderRadius: "6px 0 0 0"
            },
            "& tr > th:last-child": {
                borderRadius: "0 6px 0 0"
            },
        }
    }
}
