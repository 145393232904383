import React from "react";
import { withStyles } from "@mui/styles";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { EmailOutlined as MailIcon, RestartAltRounded as ResetIcon } from "@mui/icons-material";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { Notification, NotificationTypes } from "../../../common/Notification";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import regexp from "../../../constants/regexp";
import agent from "../../../agent/agent";
import {deleteCookie, getCookie, setCookie} from "../../../helper/cookie";
import { PENDING_PHONE_NUMBERS_COOKIE_KEY } from "../../../constants/cookieKeys";
import { phoneFormat, phoneFormatClean } from "../../../common/Formater";
import moment from "moment";
import queryString from "query-string";

let interval = null;

class Authorization extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                phone: "",
                code: "",
            },

            stage: 1,
            verificationCode: null,

            pendingPhones: getCookie(PENDING_PHONE_NUMBERS_COOKIE_KEY),
            phoneIsPending: false,
            pendingTime: 0,
            maxPendingTime: 2 * 60,

            isLoading: false,
        };

        this.refFormik = React.createRef();
        this.refInputPhoneNumber = React.createRef();
    }

    componentDidMount = async () => {
        await this.initForm();
    }

    initForm = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });

        if (parseSearch.phone) {
            this.refFormik.current.setValues({
                phone: phoneFormat(parseSearch?.phone || ""),
                code: '',
            });

            this.setPending(phoneFormat(parseSearch?.phone || ""));

            return;
        }
    }

    // Логика отправки формы
    onSubmit = async (form) => {

        await this.setState({ form });

        // Получение кода верификации
        if (this.state.stage === 1) {
            await this.getVerificationCode();

            return null
        }

        // Авторизация пользователя
        if (this.state.stage === 2) {
            await this.authUser();

            return null
        }

    }
    getVerificationCode = async () => {

        await this.setState({ isLoading: true });

        const response = await agent.post("/auth", {
            phone: ( this.state.form.phone || "" ).replace(/\D+/g, ""),
            role: "client",
        }).then((res) => {
            return res
        }).catch((err) => {
            return {
                error: err.response,
                status: err.response.status,
            }
        });
        if (response.error) {
            await this.setState({ isLoading: false });

            let errorMessage = response.error?.data?.message || "Возникла ошибка, попробуйте позже";

            if (response.status === 429) {
                errorMessage = 'Превышен лимит запросов, попробуйте через 5 минут';
            }

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            });

            return null
        }

        await this.setPhoneToPendingList(this.state.form.phone);
        await this.setPending(this.state.form.phone);

        this.setState({
            verificationCode: response?.data?.verificationId,
            stage: 2
        });

        await this.setState({ isLoading: false });
        Notification({
            message: "На Ваш номер скоро поступит звонок",
            type: NotificationTypes.success,
        });
    }
    authUser = async () => {
        await this.setState({ isLoading: true });

        // Подтверждение авторизации
        const responseConfirm = await agent.get(`/auth/confirm?id=${ this.state.verificationCode }&code=${ this.state.form.code }`).then((res) => {
            return res.data
        }).catch((err) => {
            return {
                error: err.response,
                status: err.response.status,
            }
        });
        if (responseConfirm.error) {
            await this.setState({ isLoading: false });

            let errorMessage = responseConfirm.error.data.message || "Возникла ошибка, попробуйте позже";

            if (responseConfirm.status === 429) {
                errorMessage = 'Превышен лимит запросов, попробуйте через 5 минут'
            }

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            });

            return null
        }

        // Устоновка JWT токена
        setCookie('jwtAuthToken', responseConfirm.jwt, 365);
        agent.defaults.headers['x-auth-token'] = responseConfirm.jwt;

        // Получение пользователя
        const responseUser = await agent.get("/auth/me").then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responseUser.error) {
            return null
        }

        if (responseUser?.user?.role !== "client") {
            deleteCookie('jwtAuthToken');
            agent.defaults.headers['x-auth-token'] = "";

            Notification({
                type: NotificationTypes.error,
                message: "У вас нет доступа до данного сервиса, обратитесь к администратору."
            })

            await this.setState({ isLoading: false });

            return
        }

        this.props.setUser(responseUser.user);

        await this.setState({ isLoading: false });
    }

    // Изменения номера телефона
    changePhoneInput = ({ target }) => {
        const {
            name,
            value
        } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = value;
        this.refFormik.current.setValues(newValues);

        this.setPending(newValues[name]);
    };

    // Изменение формы
    changeForm = ({ target }) => {
        const {
            name,
            value
        } = target;

        let newValues = this.refFormik.current.values;
        newValues[name] = value;
        this.refFormik.current.setValues(newValues);
    }

    //СБРОС
    resetForm = () => {
        this.setState({
            form: {
                code: "",
            },
            stage: 1,
            verificationCode: null,
        });

        this.setPending(this.state.form.phone);

        this.refInputPhoneNumber.current.focus();
    }

    // Добавить номер в список ожидания след звонка с кодом
    setPhoneToPendingList = (phone) => {
        if (!phone) {
            return;
        }

        if (!this.state.pendingPhones) {
            const newPendingPhones = [
                {
                    phone: phoneFormatClean(phone),
                    waiting_start_time: moment().format('x'),
                }
            ];

            setCookie(PENDING_PHONE_NUMBERS_COOKIE_KEY, JSON.stringify(newPendingPhones), 1);

            this.setState({
                pendingPhones: getCookie(PENDING_PHONE_NUMBERS_COOKIE_KEY),
            });

            return;
        }

        const pendingPhones = JSON.parse(this.state.pendingPhones);

        const newPendingPhones = pendingPhones.filter(pendingPhone => pendingPhone.phone !== phoneFormatClean(phone));
        newPendingPhones.push({
            phone: phoneFormatClean(phone),
            waiting_start_time: moment().format('x'),
        });

        setCookie(PENDING_PHONE_NUMBERS_COOKIE_KEY, JSON.stringify(newPendingPhones), 1);

        this.setState({
            pendingPhones: getCookie(PENDING_PHONE_NUMBERS_COOKIE_KEY),
        });
    };

    // Установить время ожидания
    setPending = (phone) => {
        this.setState({
            phoneIsPending: false,
            pendingTime: 0,
        });

        if (phoneFormatClean(phone).length === 11 && this.checkPhoneInPendingList(phone)) {
            const waitingStartTime = this.getWaitingStartTimePhoneInPendingList(phone);
            const pendingTime = this.getPendingTime(waitingStartTime);

            if (pendingTime >= this.state.maxPendingTime) {
                return;
            }

            this.setState({
                phoneIsPending: true,
                pendingTime: this.state.maxPendingTime - pendingTime,
            });

            setTimeout(() => {
                this.startPendingTime();
            });
        } else {
            clearTimeout(interval);
        }
    };

    // Стартовать таймер времени ожидания
    startPendingTime = () => {
        clearInterval(interval);

        interval = setInterval(this.decrementPendingTime, 1000);
    };

    // Убавление времени ожидания
    decrementPendingTime = () => {
        if (this.state.pendingTime === 0) {
            this.setState({
                phoneIsPending: false,
                pendingTime: 0,
            });

            clearInterval(interval);

            return;
        }

        this.setState((prevState) => ( {
            pendingTime: prevState.pendingTime - 1,
        } ));
    };

    // Проверка наличия номера в списке ожидания след звонка с кодом
    checkPhoneInPendingList = (phone) => {
        if (!this.state.pendingPhones || !phone) {
            return false;
        }

        const pendingPhones = JSON.parse(this.state.pendingPhones);

        return pendingPhones.some(pendingPhone => pendingPhone.phone === phoneFormatClean(phone));
    };

    // Получить время начала ожидания номера из списка ожидания след звонка с кодом
    getWaitingStartTimePhoneInPendingList = (phone) => {
        if (!this.state.pendingPhones || !phone || !this.checkPhoneInPendingList(phone)) {
            return 0;
        }

        const pendingPhones = JSON.parse(this.state.pendingPhones);

        return pendingPhones.find(pendingPhone => pendingPhone.phone === phoneFormatClean(phone)).waiting_start_time;
    };

    // Получить время ожидания номера
    getPendingTime = (waitingStartTime) => {
        if (!waitingStartTime) {
            return 0;
        }

        const lastUpdateDate = moment(waitingStartTime, 'x');

        return moment().diff(lastUpdateDate, 's');
    };

    render() {
        const {
            classes
        } = this.props;

        const {
            form,
            stage,

            phoneIsPending,
            pendingTime,

            isLoading
        } = this.state;

        return (
            <Box display="flex" flexDirection="column" alignItems="center">

                <Box mb={ 8 }>
                    <Typography textAlign="center" variant="h1">Вход в личный кабинет</Typography>
                    <Typography mt={ 1 } textAlign="center" variant="h4">Управление вашими заказами</Typography>
                </Box>

                <Box flex="1" maxWidth={ 640 } width="100%" display="flex" flexDirection="column" alignItems="center"
                     justifyContent="center"
                     height="100%">
                    <Formik
                        innerRef={ this.refFormik }
                        initialValues={ form }
                        validationSchema={ Boolean(stage === 1) ? validationSchemaAuth : validationSchemaConfirm }
                        onSubmit={ this.onSubmit }
                    >
                        { (props) => {
                            const {
                                values,
                                errors,
                                touched,
                                handleSubmit,
                            } = props;

                            return (
                                <Box width="100%" px={ 8 } py={ 4 }>
                                    <form
                                        onKeyDown={ (event) => {
                                            if (event.keyCode === 13) {
                                                event.preventDefault();
                                                event.stopPropagation();

                                                handleSubmit();
                                            }
                                        } }
                                    >
                                        <Box mb={ 4 }>
                                            <InputMask
                                                mask="+7 (099) 999 - 99 - 99"
                                                value={ values.phone }
                                                maskChar=""
                                                onChange={ this.changePhoneInput }
                                                formatChars={ {
                                                    '0': '[489]',
                                                    '9': '[0-9]',
                                                } }
                                                disabled={ Boolean(stage === 2) }
                                            >
                                                { () => (
                                                    <TextField
                                                        inputRef={ this.refInputPhoneNumber }
                                                        error={ Boolean(touched.phone && errors.phone) }
                                                        helperText={ touched.phone && errors.phone }
                                                        name="phone"
                                                        label="Номер телефона"
                                                        placeholder="+7 (999) 999 - 99 - 99"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={ {
                                                            endAdornment: stage === 2 && (
                                                                <IconButton
                                                                    color="error"

                                                                    onClick={ this.resetForm }
                                                                >
                                                                    <span className={ classes.resetButtonText }>изменить номер</span>
                                                                    <ResetIcon color="error" fontSize="small"/>
                                                                </IconButton>
                                                            ),
                                                        } }
                                                    />
                                                ) }
                                            </InputMask>
                                        </Box>

                                        { Boolean(stage === 2) && (
                                            <Box mb={ 4 }>
                                                <InputMask
                                                    mask="9999"
                                                    value={ values.code }
                                                    maskChar=""
                                                    onChange={ this.changeForm }
                                                >
                                                    { () =>
                                                        <TextField
                                                            error={ Boolean(touched.code && errors.code) }
                                                            helperText={ touched.code && errors.code }
                                                            name="code"
                                                            label="Последние 4 цифры"
                                                            placeholder="****"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputProps={ {
                                                                endAdornment: stage === 2 && (
                                                                    <IconButton
                                                                        className={ classes.resetButton }
                                                                        color="error"
                                                                        disabled={ phoneIsPending }
                                                                        onClick={ this.getVerificationCode }
                                                                    >
                                                                        <span className={ classes.resetButtonText }>повторный запрос звонка</span>
                                                                        { phoneIsPending
                                                                            ?
                                                                            <span className={ classes.resetButtonText }>
                                                                                { moment(pendingTime * 1000).format('mm:ss') }
                                                                            </span>
                                                                            :
                                                                            <MailIcon color="error" fontSize="small"/>
                                                                        }
                                                                    </IconButton>
                                                                ),
                                                            } }
                                                        />
                                                    }
                                                </InputMask>
                                            </Box>
                                        ) }

                                        <Box>
                                            {
                                                Boolean(stage === 1)
                                                    ?
                                                    <>
                                                        {
                                                            ( Boolean(!phoneIsPending) && Boolean(stage === 1) )
                                                                ? (
                                                                    <LoadingButton
                                                                        variant="contained"
                                                                        size="large"
                                                                        loading={ isLoading }
                                                                        fullWidth
                                                                        onClick={ handleSubmit }
                                                                    >
                                                                        Запросить звонок для получения кода
                                                                    </LoadingButton>
                                                                )
                                                                : (
                                                                    <LoadingButton
                                                                        className={ classes.pendingButton }
                                                                        variant="contained"
                                                                        size="large"
                                                                        loading={ phoneIsPending }
                                                                        loadingIndicator={ `Повторно запросить звонок можно спустя ${ moment(pendingTime * 1000).format('mm:ss') }` }
                                                                        fullWidth
                                                                    >
                                                                    </LoadingButton>
                                                                )
                                                        }
                                                    </>
                                                    :
                                                    <LoadingButton
                                                        variant="contained"
                                                        size="large"
                                                        loading={ isLoading }
                                                        fullWidth
                                                        onClick={ handleSubmit }
                                                    >
                                                        Авторизоваться
                                                    </LoadingButton>
                                            }
                                        </Box>

                                    </form>
                                </Box>
                            );
                        } }
                    </Formik>
                </Box>

            </Box>
        );
    }
}

const validationSchemaAuth = Yup.object().shape({
    phone: Yup.string()
        .matches(regexp.phone, "Введите номер телефона в формате - +7 (999) 999 - 99 - 99")
        .required('Введите номер телефона'),
});
const validationSchemaConfirm = Yup.object().shape({
    phone: Yup.string()
        .matches(regexp.phone, "Введите номер телефона в формате - +7 (999) 999 - 99 - 99")
        .required('Введите номер телефона'),
    code: Yup.string()
        .matches(/^[0-9]{4}$/, "Код состоит из последних 4-х цифр номера")
        .required('Введите последние 4 цифры номера'),
});

const styles = {
    resetButton: {
        borderRadius: 0,
        padding: '0 5px',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    resetButtonText: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: 'normal',

        "@media (max-width: 640px)": {
            fontSize: "10px",
        },
    },

    pendingButton: {
        "@media (max-width: 640px)": {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            '& .MuiLoadingButton-loadingIndicator': {
                display: 'block',
                width: '100%',
                position: 'static',
                transform: 'none',
            },
        },
    },

    pendingBlock: {
        display: 'flex',
        justifyContent: 'center',
    },

    pendingInfoTextBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },

    pendingInfoText: {
        color: 'rgba(0, 0, 0, .3)',
    },

    smallButton: {
        width: 'max-content',
        height: 'max-content',
        padding: '0',
        fontWeight: '400',
        backgroundColor: 'transparent',
        color: '#84b92c',

        '&:hover': {
            backgroundColor: 'transparent',
            color: '#90ae5d',
            border: 'none',
            boxShadow: 'none',
        },

        '&:disabled': {
            backgroundColor: 'transparent',
        },
    },
};

export default withStyles(styles)(Authorization);
