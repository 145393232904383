import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { convertorToPiece } from "../../../../../../helper/convertor";
import { numberFormat } from "../../../../../../common/Formater";

const DialogConfirmReturn = (props) => {
    const {
        order,
        isOpen,
        returnItems,
        onClose,
        onConfirm,
    } = props;
    const classes = useStyles();

    const returnOrderItems = order.orderItems.filter( item => returnItems.find( returnItem => returnItem.productId === item.product.id ) );

    const renderReturnQuantity = (orderItem) => {
        const returnItem = returnItems.find( item => item.productId === orderItem.product.id );
        const piece = convertorToPiece( returnItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0 );

        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( returnItem.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece( orderItem?.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0 );

        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( orderItem?.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    const handleClose = () => {
        onClose();
    };

    const onSubmit = () => {
        const newForm = returnOrderItems.map( item => {
            return {
                orderItemId: item.id,
                quantity: item.quantity,
            };
        } );

        onConfirm( newForm );
        handleClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={ isOpen }
            onClose={ handleClose }
        >
            <DialogTitle>
                Подтвердить частичный возврат
            </DialogTitle>
            <DialogContent>
                <Box className={ classes.root }>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="40%">Наименование</TableCell>
                                    <TableCell>Кол-во</TableCell>
                                    <TableCell>Кол-во возврата</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { returnOrderItems.map( item => (
                                    <>
                                        <TableRow key={ `return-order-item-${ item.id }` }>
                                            <TableCell sx={ { whiteSpace: "nowrap" } }>
                                                { item?.productName || '-' }
                                            </TableCell>
                                            <TableCell sx={ { whiteSpace: "nowrap" } }>
                                                { renderQuantity( item ) }
                                            </TableCell>
                                            <TableCell sx={ { whiteSpace: "nowrap" } }>
                                                { renderReturnQuantity( item ) }
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ) ) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <DialogActions className={ classes.actions }>
                    <Button
                        variant="outlined"
                        className={ classes.button }

                        onClick={ handleClose }
                    >
                        Отменить
                    </Button>
                    <Button
                        variant="contained"
                        className={ classes.button }

                        onClick={ onSubmit }
                    >
                        Подтвердить
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles( {
    root: {
        paddingTop: 10,
    },
    row: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },

    formGroup: {
        marginTop: 16,
    },

    actions: {
        marginTop: 16,
        padding: 0,

        "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: 8,
        },
    },

    button: {
        borderRadius: "4px",
        textTransform: "initial",

        "@media (max-width: 600px)": {
            width: "100%",
            height: 30,
            margin: "0 !important",
        },
    },
} )

export default React.memo( DialogConfirmReturn );
