import axios from 'axios';
import {getCookie} from "../helper/cookie";

const axiosInstance = axios.create( {
    baseURL: process.env.REACT_APP_HOST_API,
    headers: {
        'x-auth-token': getCookie('jwtAuthToken')
    }
} )

export default axiosInstance
