import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { RouteWithLayout } from "../components";
import { Default as DefaultLayout } from "../layouts";
import { Order as OrderPage, Orders as OrdersPage, Profile as ProfilePage, Wallet as WalletPage } from "../pages";
import { delegatedOrderPage } from "./DelegatedOrderRouter";

const pages = [
    {
        path: '/orders',
        component: OrdersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/orders/:orderId',
        component: OrderPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/profile',
        component: ProfilePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/wallet',
        component: WalletPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    delegatedOrderPage
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map( (page, idx) => (
                    <RouteWithLayout
                        key={ 'page-' + idx }
                        { ...page }
                    />
                ) )
            }

            <Redirect to="/orders"/>
        </Switch>
    );
};

export default MainRoutes;
