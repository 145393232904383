import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const OrderActions = (props) => {
    const {
        order,
        onConfirmReturn,
    } = props;
    const classes = useStyles();

    const handleClickReturnConfirmButton = () => {
        onConfirmReturn( false );
    };

    const renderBtns = () => {
        switch (order.deliveryStatus) {
            case "partialReturn":
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={ { height: 40, borderRadius: 1 } }

                        onClick={ handleClickReturnConfirmButton }
                    >
                        Подтвердить частичный возврат
                    </Button>
                );
            case "fullReturn":
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={ { height: 40, borderRadius: 1 } }

                        onClick={ handleClickReturnConfirmButton }
                    >
                        Подтвердить полный возврат
                    </Button>
                );
            default:
                return '';
        }
    };
    return (
        <Box className={ classes.root }>
            <Typography className={ classes.title }>
                Действия
            </Typography>
            <Box>
                { renderBtns() }
            </Box>
        </Box>
    );
};

const useStyles = makeStyles( () => ( {
    root: {
        marginTop: 12,
        padding: 20,
        backgroundColor: "rgba(117, 117, 117, 0.05)",
        border: "1px solid #757575",
        borderRadius: 8
    },
    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500",

        marginBottom: 18
    },
} ) );

export default OrderActions;
