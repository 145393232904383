import React from "react";
import {
    Box,
    Button,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { Edit as EditIcon, HelpOutlineRounded as HelpIcon, } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { phoneFormat } from "../../../../../../common/Formater";
import { formatPhone } from "../../../../../../helper/formatPhone";
import { userIsLegal } from "../../../../../../helper/userType";
import userRoles from "../../../../../../constants/userRoles";
import organizationTypes from "../../../../../../constants/organizationTypes";

const UserInfo = (props) => {
    const {
        user,
        onOpenEditUser,
        onOpenAddBank,
        onOpenEditBank
    } = props;

    const classes = useStyles();

    const getUserRole = (role) => {
        const userRole = userRoles.find( (r) => r.value === role ).label;
        return userRole ? userRole : role;

    };

    return (
        <Box className={ classes.root }>
            <Box className={ classes.rowContent }>
                <Typography variant="h3">Пользователь</Typography>
                <Tooltip title="Изменить данные">
                    <IconButton
                        onClick={ onOpenEditUser.bind( this, true ) }
                    >
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
            <TableContainer>
                <Table className={ classes.table }>
                    <TableBody>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                № (ID)
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.id || "—" }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Роль
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.role ? getUserRole( user.role ) : "—" }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Тип клиента
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { userIsLegal( user )
                                    ? "Юридическое лицо"
                                    : "Физическое лицо"
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Фамилия
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.lastName || "—" }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Имя
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.firstName || "—" }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Email
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.email || "—" }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Логин
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.username || "—" }
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="25%" className={ classes.tableLabel }>
                                Телефон
                            </TableCell>
                            <TableCell className={ classes.tableMessage }>
                                { user.phone ? formatPhone( user.phone ) : "—" }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </TableContainer>

            { user?.organization && (
                <>
                    <Box className={ classes.rowContent } mt={ 2 }>
                        <Typography variant="h3">Организация</Typography>
                        <Tooltip title="Для изменения данных организации обратитесь к администратору">
                            <IconButton>
                                <HelpIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <TableContainer>
                        <Table className={ classes.table }>
                            <TableBody>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Роль пользователя
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { userIsLegal( user ) ? "Владелец" : "Участник" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Форма
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { organizationTypes.find( item => item.value === user?.organization?.type ).label || '-' }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Название
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.name || "—" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        ИНН
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.inn || "—" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        КПП
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.kpp || "—" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        ОГРН/ИП
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.ogrn || "—" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Email
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.email || "—" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Телефон
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.phone
                                            ? (
                                                <Tooltip title="Позвонить" arrow>
                                                    <Link href={ `tel:+${ user?.organization?.phone }` }>
                                                        { phoneFormat( user?.organization?.phone ) || "—" }
                                                    </Link>
                                                </Tooltip>
                                            )
                                            : "-"
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Адрес
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.address || "—" }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" className={ classes.tableLabel }>
                                        Директор
                                    </TableCell>
                                    <TableCell className={ classes.tableMessage }>
                                        { user?.organization?.ceoName || "—" }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) }

            { !userIsLegal( user ) && (
                <>
                    <Box className={ classes.rowContent } mt={ 2 }>
                        <Typography variant="h3">Банк</Typography>
                        { ( user?.bankNameForWithdrawal && user.bankNameForWithdrawal.length > 0 ) && (
                            <Tooltip title="Изменить данные">
                                <IconButton
                                    onClick={ onOpenEditBank.bind( this, true ) }
                                >
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        ) }
                    </Box>
                    { ( user?.bankNameForWithdrawal && user.bankNameForWithdrawal.length > 0 )
                        ? (
                            <TableContainer>
                                <Table className={ classes.table }>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="25%" className={ classes.tableLabel }>
                                                Название
                                            </TableCell>
                                            <TableCell className={ classes.tableMessage }>
                                                { user?.bankNameForWithdrawal || '-' }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )
                        : <Button
                            variant="contained"
                            size="small"
                            sx={ { marginTop: 1 } }

                            onClick={ onOpenAddBank.bind( this, true ) }
                        >
                            Добавить банк
                        </Button>
                    }
                </>
            ) }
        </Box>
    );
};

const useStyles = makeStyles( () => ( {
    root: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "16px 32px 24px",

        "@media (max-width: 900px)": {
            padding: "12px 16px",
        },
    },

    rowContent: {
        display: "flex",
        alignItems: 'center',
        gap: 8,
    },

    table: {
        boxShadow: "none",
        borderRadius: 0,
    },
    tableLabel: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#464646",

            "@media (max-width: 900px)": {
                paddingRight: 10,
            },
        },
    },
    tableMessage: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "black",
            fontWeight: "600",

            "@media (max-width: 900px)": {
                whiteSpace: "nowrap",
            },
        },
    },

    button: {
        height: 30,
        padding: "0 24px",
        margin: "15px 0 0 0",
        borderRadius: 4,

        textTransform: "initial",
        fontSize: 16,
    }
} ) );

export default UserInfo;
