import React from "react";
import { Sidebar as SidebarComponent } from "./components";
import { Box, Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const Default = ({ children }) => {
    const isTablet = useMediaQuery( '(max-width:900px)' );


    return (
        <Box display='flex' alignItems='flex-start' flexDirection={ isTablet ? 'column' : 'row' }>

            <SidebarComponent/>

            <Container maxWidth="100%">
                <Box width="100%">
                    { children }
                </Box>
            </Container>

        </Box>
    );
};

export default Default;
