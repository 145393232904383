// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Orders from './Orders';

export default compose(
    connect(
        state => ( {} ),
        dispatch => ( {} ),
    ),
)( Orders );
