import { numberFormat } from "../../../../../../common/Formater";
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, {useState} from "react";
import { ReactSVG } from "react-svg";
import deliveryStatus from "../../../../../../constants/deliveryStatus";
import paymentStatus from "../../../../../../constants/paymentStatus";
import { convertorNumber, convertorToPiece } from "../../../../../../helper/convertor";
import DeliveryIcon from "../../../../../../assets/svg/delivery/delivery.svg";
import PickupIcon from "../../../../../../assets/svg/delivery/pickup.svg";
import { deliveryTime } from "../../../../../../helper/deliveryTime";
import {LoadingButton, Pagination, Skeleton} from "@mui/lab";
import {userIsLegal} from "../../../../../../helper/userType";
import {useSelector} from "react-redux";
import agent from "../../../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../../../common/Notification";


const TableCustom = (props) => {
    const {
        filter,
        data,
        settings,
        pagination,
        visibleColumns,
        isLoading,

        onChangePagination,
        onChangeFilter,
        routePageOrder,
    } = props;
    const classes = useStyles();
    const user = useSelector( state => state.global.user);
    const [isResponseLoading, setIsResponseLoading] = useState(false);


    const _renderIconDeliveryMethod = (type) => {
        let pathIcon = "";

        if (type === "pickup") {
            pathIcon =
                require( "../../../../../../assets/svg/delivery/pickup.svg" ).default;
        }
        if (type === "delivery") {
            pathIcon =
                require( "../../../../../../assets/svg/delivery/delivery.svg" ).default;
        }

        return <ReactSVG src={ pathIcon }/>;
    };

    const _renderProductQuantity = (product) => {
        const piece = convertorToPiece( product.quantity, product?.product?.pieceToCbmConversionFactor || 0 );
        return (
            <>
                <Typography>{ piece } шт.</Typography>
                <Typography>{ numberFormat( product.quantity, 4 ) } м³</Typography>
            </>
        );
    };

    const _getDeliveryDate = (row) => {
        const date = moment( row.deliveryDate ).format( "DD.MM.YYYY" );
        const deliveryTimes = deliveryTime( settings );
        const time = deliveryTimes.filter( time => time.value === row.deliveryTimespan );
        return (
            <>
                <Typography>{ date }</Typography>
                <Typography>
                    { time[0]?.label || "Время не указано" }
                </Typography>
            </>
        );
    }

    const _getDeliveryAddress = (row) => {
        const deliveryMethod = row.deliveryMethod;

        if (deliveryMethod === "pickup") {
            return (
                <Tooltip title="Самовывоз" arrow>
                    <Box className={ classes.delivery }>
                        <img src={ PickupIcon }/>
                        Самовывоз
                    </Box>
                </Tooltip>
            );
        }

        return (
            <Tooltip title="Доставка" arrow>
                <Box className={ classes.delivery }>
                    <img src={ DeliveryIcon }/>
                    { row?.deliveryAddress || "Милицейский адрес не найден" }
                </Box>
            </Tooltip>
        );
    }

    const _getStatusOrder = () => {
    }

    const handleChangePage = (event, page) => {
        let newPagination = { ...pagination };
        newPagination.page = page;

        onChangePagination( newPagination )
    }

    const handleChangeFilter = (name, value) => {
        const newFilter = { ...filter };
        newFilter[name] = value;

        onChangeFilter( newFilter, true );
    };

    const onAddPayment = async (e, orderId) => {
        e.stopPropagation();

        setIsResponseLoading(true);

        const body = {
            orderId: orderId,
            type: "online",
            returnUrlForOnlinePayment: `https://client.pilorami.online/orders/${ orderId }`,
        };

        const response = await agent.post( `payments`, body )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            setIsResponseLoading(false);

            Notification( {
                message: response.error?.data?.message || "Ошибка при оплате",
                type: NotificationTypes.error,
            } );

            return null;
        }

        setIsResponseLoading(false);

        window.location.href = response.online.paymentUrl;
    }

    const onWatchReceipt = async (e, orderId) => {
        e.stopPropagation();
        setIsResponseLoading(true);
        const response = await agent.get( `payments?orderId=${orderId}` )
            .then( res => res.data )
            .catch( err => {
                return { error: err.response }
            } );

        if (response.error) {
            setIsResponseLoading(false);

            Notification( {
                message: response.error?.data?.message || "Ошибка при оплате",
                type: NotificationTypes.error,
            } );

            return null;
        }
        //
        setIsResponseLoading(false);
        const receiptUrl = response.payments[0].invoices[0].documentLink;
        window.open(receiptUrl, '_blank');
    }

    return (
        <>

            <TableContainer component={ Paper }>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <EnhancedTableHead
                                visibleColumns={ visibleColumns }
                                filter={ filter }
                                handleChangeFilter={ handleChangeFilter }
                            />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { isLoading
                            ? <>
                                { [ 1, 2, 3, 4, 5 ].map(item => (
                                    <TableRow key={ item }>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                        <TableCell><Skeleton/></TableCell>
                                    </TableRow>
                                )) }
                            </>
                            : <>
                                { data.length > 0
                                    ? <>
                                        {
                                            ( data || [] ).map((row, index) => (
                                                <TableRow
                                                    key={ `table-row-${ index }` }
                                                    hover={ true }
                                                    onClick={ () => routePageOrder(row.id) }
                                                >
                                                    <TableCell>
                                                        { row.id }
                                                    </TableCell>
                                                    <TableCell>
                                                        { _getDeliveryDate(row) }
                                                    </TableCell>
                                                    <TableCell>
                                                        { deliveryStatus[row.deliveryStatus] }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            paymentStatus[row.paymentStatus] === 'Не оплачено' && userIsLegal(user) ? (
                                                                <LoadingButton
                                                                    fullWidth
                                                                    size="small"
                                                                    variant="contained"
                                                                    loading={isResponseLoading}
                                                                    color='error'
                                                                    onClick={(e) => onWatchReceipt(e, row.id)}
                                                                >
                                                                    Посмотреть счет
                                                                </LoadingButton>
                                                            ) : (
                                                                paymentStatus[row.paymentStatus] === 'Не оплачено' && !userIsLegal(user) ? (
                                                                    <LoadingButton
                                                                        fullWidth
                                                                        size="small"
                                                                        variant="contained"
                                                                        loading={isResponseLoading}
                                                                        color='error'
                                                                        onClick={(e) => onAddPayment(e, row.id)}
                                                                    >
                                                                        Оплатить
                                                                    </LoadingButton>
                                                                ) : (
                                                                    paymentStatus[row.paymentStatus]
                                                                )
                                                            )
                                                        }
                                                    </TableCell>
                                                    <TableCell colSpan={ 3 }>
                                                        <Table className={ classes.tableSmall }>
                                                            <TableBody>
                                                                { ( row.orderItems || [] ).map((product) => (
                                                                    <TableRow key={ `order-product-${ product.id }` }>
                                                                        <TableCell width={ 140 }>
                                                                            { product.productName }
                                                                        </TableCell>
                                                                        <TableCell width={ 140 }>
                                                                            { _renderProductQuantity(product) }
                                                                        </TableCell>
                                                                        <TableCell width={ 80 }>
                                                                            { numberFormat(product.price, 2, '.') } ₽
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )) }
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                    <TableCell>
                                                        { convertorNumber(row.finalDeliveryPrice, 2, '.') } ₽
                                                    </TableCell>
                                                    <TableCell>
                                                        { convertorNumber(row.totalPrice, 2, '.') } ₽
                                                    </TableCell>
                                                    <TableCell>
                                                        { _getDeliveryAddress(row) }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </>
                                    : (
                                        <TableRow>
                                            <TableCell colSpan={ 10 } align='center'>Нет заказов</TableCell>
                                        </TableRow>
                                    )
                                }
                            </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={ 3 }/>

            { data.length > 0 && (
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Pagination
                            page={ Number(pagination.page) }
                            count={ Number(pagination.totalPage) }

                            onChange={ handleChangePage }
                        />
                    </Grid>
                </Grid>
            ) }
        </>
    );
};

const EnhancedTableHead = (props) => {
    const {
        visibleColumns,
        filter,

        handleChangeFilter
    } = props;
    const classes = useStyles();

    const handleChangeSort = (col) => {
        if (filter.sortInfo === `-${ col }`) {
            handleChangeFilter( "sortInfo", col );
        } else {
            handleChangeFilter( "sortInfo", `-${ col }` );
        }
    };
    return (
        <>
            { visibleColumns.map( column => (
                <TableCell
                    key={ column.id }
                    width={ column.width }
                >
                    { column.sortable
                        ? (
                            <TableSortLabel
                                classes={ {
                                    active: classes.activeSort,
                                    root: classes.sort,
                                } }
                                active={ filter.sortInfo === column.id || filter.sortInfo === `-${ column.id }` }
                                direction={ filter.sortInfo === column.id ? "desc" : "asc" }

                                onClick={ handleChangeSort.bind( this, column.id ) }
                            >
                                { column.label }
                            </TableSortLabel>
                        )
                        : column.label
                    }
                </TableCell>
            ) ) }
        </>
    );
};

const useStyles = makeStyles( () => ( {
    tableBody: {},

    tableSmall: {
        "& tr td": {
            padding: "2px 20px",
            "&:first-child": {
                paddingLeft: 0,
            },
            "&:last-child": {
                paddingRight: 0,
            }
        }
    },

    delivery: {
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: 8
        }
    },

    sort: {
        "&.MuiButtonBase-root": {
            transition: "all .2s linear",
            "&:hover": {
                color: "rgba(255,255,255, .5)",
                "& .MuiSvgIcon-root": {
                    fill: "#FFFFFF",
                }
            }
        }
    },
    activeSort: {
        '&.MuiButtonBase-root.Mui-active': {
            color: "#FFFFFF",

            "& .MuiSvgIcon-root": {
                "& path": {
                    fill: "#FFFFFF",
                }
            }
        },
    },
} ) );

export default TableCustom;
