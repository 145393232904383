export default {
    'new': "Новый",
    'inProgress': "В работе",
    'partiallyReady': "Частично готов",
    'readyForShipment': "Готов к отгрузке",
    'notReadyForShipment': "Не готов",
    'dutyWarehouse': "На дежурном складе",
    'courierInstalled': "Ожидает погрузки",
    'loaded': "Ожидает погрузки",
    'transferringToDelivery': "Ожидает погрузки",
    'enRoute': "В пути",
    'transferringToClient': "Ожидается подтверждение передачи заказа кодом заказчика",
    'received': "Выполнен",
    'partialReturn': "Частичный возврат",
    'fullReturn': "Полный возврат",
    'canceled': "Отменен",
};
