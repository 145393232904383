import agent from "../agent/agent";

const getAddressValue = async (geoObject) => {
    if (geoObject[0] !== null && geoObject[1] !== null) {
        const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
        const token = process.env.REACT_APP_API_KEY_DADATA;

        const suggestions = await agent( {
            method: 'POST',
            mode: "cors",
            url: url,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            data: JSON.stringify( {
                lat: geoObject[0],
                lon: geoObject[1],
            } )
        } ).then( (res) => res.data.suggestions ).catch( (err) => [] );

        return {
            ...suggestions[0],
            value: suggestions[0]?.value || "Милицейский адрес не найден",
        };
    }
};

export {
    getAddressValue
};
