import { RouteWithLayout } from "../components";
import React from "react";
import { Minimal as MinimalLayout } from "../layouts";
import { DelegatedOrder as DelegatedOrderPage, } from "../pages";
import { Switch } from "react-router-dom";


export const delegatedOrderPage = {
    path: '/delegated-order',
    component: DelegatedOrderPage,
    layout: MinimalLayout,
    rules: [],
    exact: true
};

const DelegatedOrderRouter = () => {
    return (
        <Switch>
            <RouteWithLayout
                key={ 'page-' + delegatedOrderPage.path }
                { ...delegatedOrderPage }
            />
        </Switch>
    );
}

export default DelegatedOrderRouter;
