export default {
    styleOverrides: {
        root: {
            transition: 'background-color .3s linear',
            "&.MuiTableRow-hover": {
                cursor: "pointer",
                "&:hover": {
                    "& > *": {
                        backgroundColor: "#EFEFEF",
                        transition: 'background-color .3s linear',
                    }
                }
            }
        }
    }



}
